import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig, axiosFileConfig} from "../constants/constants";
import {loading} from "./helperActions";
import fileDownload from "js-file-download";

export const addResults = (data, level, year, term, exam, subject) => dispatch => {
    dispatch(
        loading(actionTypes.ADD_RESULTS, {}, 0, true)
    );
    axios.post(`${urls.RESULTS_URL}${level}/${year}/${term}/${exam}/${subject}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.ADD_RESULTS, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.ADD_RESULTS, error, true)
                );
            }
        )
};

export const editResults = (data, level, year, term, category, subjectCode) => dispatch => {
    dispatch(
        loading(actionTypes.EDIT_RESULTS, {}, 0, true)
    );
    axios.put(`${urls.RESULTS_URL}${level}/${year}/${term}/${category}/${subjectCode}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.EDIT_RESULTS, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.EDIT_RESULTS, error, true)
                );
            }
        )
};

export const getResults = (level, stream, year, term, category, subjectCode) => dispatch => {
    dispatch(
        loading(actionTypes.GET_RESULTS, {}, 0, true)
    );
    axios.get(`${urls.RESULTS_URL}${level}/${year}/${term}/${category}/${subjectCode}/${stream}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_RESULTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_RESULTS, error)
                );
            }
        )
};

export const getOverallResults = (level, year, term, category) => dispatch => {
    dispatch(
        loading(actionTypes.GET_OVERALL_RESULTS, {}, 0, true)
    );
    axios.get(`${urls.RESULTS_URL}teacher/${level}/${year}/${term}/${category}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_OVERALL_RESULTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_OVERALL_RESULTS, error)
                );
            }
        )
};

export const exportResults = (level, stream, year, term, category, subjectCode) => dispatch => {
    dispatch(
        loading(actionTypes.EXPORT_RESULTS, {}, 0, true)
    );
    axios.get(`${urls.RESULTS_URL}export/${level}/${year}/${term}/${category}/${subjectCode}/${stream}`, axiosFileConfig)
        .then(response => {
            fileDownload(response.data, `results_${new Date().getTime()}.xlsx`);
            dispatch(
                handleSuccessResponse(actionTypes.EXPORT_RESULTS, {}, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(actionTypes.EXPORT_RESULTS, error, true)
                );
            }
        )
};

export const importResults = (level, stream, year, term, category, subjectCode, data) => dispatch => {
    dispatch(
        loading(actionTypes.IMPORT_RESULTS, {}, 0, true)
    );
    axios.post(`${urls.RESULTS_URL}import/${level}/${year}/${term}/${category}/${subjectCode}/${stream}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.IMPORT_RESULTS, response.data, response.status, true)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(actionTypes.IMPORT_RESULTS, error, true)
                );
            }
        )
};
