export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';
export const GET_RESULTS = 'GET_RESULTS';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const BLACKLIST_TOKEN = 'BLACKLIST_TOKEN';
export const LOAD = 'LOAD';
export const GET_STREAMS = 'GET_STREAMS';
export const GET_EVENTS = 'GET_EVENTS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const GET_TEACHING_SUBJECTS = 'GET_TEACHING_SUBJECTS';
export const GET_STREAM_STUDENTS = 'GET_STREAM_STUDENTS';
export const ADD_RESULTS = 'ADD_RESULTS';
export const EDIT_RESULTS = 'EDIT_RESULTS';
export const MARK_ATTENDANCE = 'MARK_ATTENDANCE';
export const GET_ATTENDANCE = 'GET_ATTENDANCE';
export const EDIT_ATTENDANCE = 'EDIT_ATTENDANCE';
export const GET_EVENT = 'GET_EVENT';
export const GET_OVERALL_RESULTS = 'GET_OVERALL_RESULTS';
export const ISSUE_LEAVE = 'ISSUE_LEAVE';
export const GET_ISSUED_LEAVES = 'GET_ISSUED_LEAVES';
export const EDIT_ISSUED_LEAVES = 'EDIT_ISSUED_LEAVES';
export const LOAD_DATE = 'LOAD_DATE';
export const LOADING = 'LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_CLASS_LEVEL = 'VIEW_CLASS_LEVEL';
export const GET_TERM = 'GET_TERM';
export const GET_EXAMS = 'GET_EXAMS';
export const GET_HOMEWORK= 'GET_HOMEWORK';
export const GET_HOMEWORK_QUESTIONS= 'GET_HOMEWORK_QUESTIONS';
export const ADD_HOMEWORK = 'ADD_HOMEWORK';
export const GET_NOTICES= 'GET_NOTICES';
export const GET_NOTICE= 'GET_NOTICE';
export const GET_ALL_EVENTS= 'GET_ALL_EVENTS';
export const GET_TIMETABLE= 'GET_TIMETABLE';
export const SEND_DEVICE_TOKEN= 'SEND_DEVICE_TOKEN';
export const GET_CHATS = 'GET_CHATS';
export const GET_STUDENTS = 'GET_STUDENTS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_RECEIVED_MESSAGE = 'SEND_RECEIVED_MESSAGE';
export const SEND_READ_MESSAGE = 'SEND_READ_MESSAGE';
export const GET_POSTS = 'GET_POSTS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_REPLIES = 'GET_REPLIES';
export const SEND_POSTS = 'SEND_POSTS';
export const SEND_COMMENTS = 'SEND_COMMENTS';
export const SEND_REPLIES = 'SEND_REPLIES';
export const IMPORT_RESULTS = 'IMPORT_RESULTS';
export const EXPORT_RESULTS = 'EXPORT_RESULTS';
export const DOWNLOAD_MARK_SHEET = 'DOWNLOAD_MARK_SHEET';
