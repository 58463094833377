import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getClassLevels = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_CLASS_LEVEL, {}, 0, true)
    );
    axios.get(urls.LEVEL_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_CLASS_LEVEL, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_CLASS_LEVEL, error)
            )
        )
};
