import {GET_NOTICE} from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false,
    status: 0
};

export default (state = initialState, action) => {
    const newState = {...state};

    switch (action.type) {
        case GET_NOTICE:
            newState.data = action.data;
            newState.status = action.status;
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
