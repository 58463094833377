import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DAYS_OF_WEEK} from "../../constants/constants";
import {titleCase} from "../../helpers";
import {getTimetable} from "../../actions/timetableActions";

const styles = theme => ({});

class Timetable extends Component {
    componentDidMount() {
        document.title = 'My Timetable';
        this.props.getTimetable();
    }

    render() {
        const {data, loading} = this.props;
        const {columns, rows} = data;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="my timetable"/>
                <div className="menu-drawer-h pl-4 pr-4">
                    {
                        loading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }
                    <div className='pt-3'>
                        {
                            columns && columns.length > 0 && !loading &&
                            <Paper elevation={1}>
                                <div className="table-responsive text-nowrap p-0 m-0">
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th className="text-left w-auto"/>
                                            {
                                                data.columns.map((item, index) => {
                                                    return (<th className='text-left' key={index}>
                                                        <div className='w-100 d-flex flex-row'>
                                                            <div>{item.formattedStartTime} - </div>
                                                            <div>{item.formattedEndTime}</div>
                                                        </div>
                                                    </th>);
                                                })
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            rows.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{DAYS_OF_WEEK[item.day]}</td>
                                                        {
                                                            columns.map((columnItem, columnIndex) => {
                                                                const filtered = item.items.filter(x => x.startTime === columnItem.startTime);
                                                                if (filtered[0]) {
                                                                    return (
                                                                        <td key={columnIndex}>
                                                                            <div>{titleCase(filtered[0].subjectName)}</div>
                                                                            <div className='text-black-50'>
                                                                                {titleCase(`${filtered[0].levelName} ${filtered[0].streamName}`)}
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                } else {
                                                                    return <td key={columnIndex}/>
                                                                }
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </Paper>
                        }
                        {
                            columns && columns.length === 0 &&
                            <Paper>
                                <div className='p-3'>
                                    Timetable has not been created
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Timetable.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getTimetable: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.timetableData.data,
    loading: state.timetableData.loading,
    status: state.timetableData.status,
});

export default connect(mapStateToProps, {getTimetable})(withStyles(styles, {withTheme: true})(Timetable));