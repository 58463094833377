import React, {Component} from 'react';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

class PageNotFound extends Component {
    componentDidMount() {
        document.title = 'Page Not Found';
    }

    render() {
        return (
            <MuiThemeProvider>
                <div className='p-not-found-container p-3'>
                    <div className='p-not-found-content'>
                        <h1 className='text-center text-primary font-lato font-italic text-404'>404</h1>
                        <h1 className='text-primary font-lato'>Sorry, the page your looking for is not here</h1>
                        <Link to='/' className='d-flex justify-content-center'>
                            <Button style={{
                                color: '#FFF',
                                backgroundColor: "#5AB9EA",
                            }}
                                    size='large'>
                                Go to Home
                            </Button>
                        </Link>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default PageNotFound;