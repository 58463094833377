import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import React from "react";
import ChevronLeftIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {DatePicker, DateTimePicker} from "material-ui-pickers";

export const RenderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField label={label}
               helperText={touched && error}
               error={error && touched}
               {...input}
               {...custom}
    />
);

export const RenderSelect = ({
                                 input,
                                 label,
                                 meta: {touched, error},
                                 children,
                                 style,
                                 selectStyle,
                                 ...custom
                             }) => (
    <FormControl style={style} fullWidth
                 error={error && touched}>
        <InputLabel shrink htmlFor="age-label-placeholder"
                    style={{fontSize: 30, marginLeft: 0}}>
            {label}
        </InputLabel>
        <Select
            error={error && touched}
            {...input}
            children={children}
            style={selectStyle}
            {...custom}
        />
        <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
);

export const RenderDateTimePicker = ({input, label, meta: {touched, error}, ...custom}) => (
    <DateTimePicker
        label={label}
        {...input}
        {...custom}
    />
);

export const RenderPasswordField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField label={label}
               helperText={touched && error}
               error={error && touched}
               {...input}
               {...custom}
    />
);