import {loading} from "./helperActions";
import axios from "../axios/axios";
import {FORUM_URL} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig, PARENT_TO_TEACHER} from "../constants/constants";
import {GET_COMMENTS, GET_POSTS, GET_REPLIES, SEND_COMMENTS, SEND_POSTS, SEND_REPLIES} from "../constants/actionTypes";

export const getPosts = () => dispatch => {
    dispatch(
        loading(GET_POSTS, {posts: []}, 0, true)
    );
    axios.get(`${FORUM_URL}/teacher/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_POSTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_POSTS, error)
                );
            }
        )
};

export const getComments = () => dispatch => {
    dispatch(
        loading(GET_COMMENTS, {comments: []}, 0, true)
    );
    axios.get(`${FORUM_URL}/teacher/comment/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_COMMENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_COMMENTS, error)
                );
            }
        )
};

export const getReplies = () => dispatch => {
    dispatch(
        loading(GET_REPLIES, {replies: []}, 0, true)
    );
    axios.get(`${FORUM_URL}/teacher/reply/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_REPLIES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_REPLIES, error)
                );
            }
        )
};

export const newPost = (currentPosts, newItem) => dispatch => {
    const clonedPosts = [...currentPosts];
    const filtered = clonedPosts.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0));
    if (filtered.length > 0) {
        clonedPosts.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0)) {
                clonedPosts.splice(index, 1, newItem);
            }
        });
    } else {
        clonedPosts.splice(0, 0, newItem);
    }
    const data = {posts: clonedPosts};
    dispatch(
        handleSuccessResponse(GET_POSTS, data, 200)
    );
};

export const newComment = (currentComments, newItem) => dispatch => {
    const clonedComments = [...currentComments];
    const filtered = clonedComments.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && localStorage.getItem("teacherIdNo") === newItem.fromIdNo));
    if (filtered.length > 0) {
        clonedComments.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0)) {
                clonedComments.splice(index, 1, newItem);
            }
        });
    } else {
        clonedComments.push(newItem);
    }
    const data = {comments: clonedComments};
    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const newReply = (currentReplies, newItem) => dispatch => {
    const clonedReplies = [...currentReplies];
    const filtered = clonedReplies.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && localStorage.getItem("teacherIdNo") === newItem.fromIdNo));
    if (filtered.length > 0) {
        clonedReplies.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0)) {
                clonedReplies.splice(index, 1, newItem);
            }
        });
    } else {
        clonedReplies.push(newItem);
    }
    const data = {replies: clonedReplies};
    dispatch(
        handleSuccessResponse(GET_REPLIES, data, 200)
    );
};

export const addPost = (currentPosts, newPost) => dispatch => {
    const posts = [newPost, ...currentPosts];
    const data = {posts: posts};
    dispatch(
        handleSuccessResponse(GET_POSTS, data, 200)
    );
};

export const addComment = (currentComments, newComment) => dispatch => {
    const comments = [...currentComments, newComment];
    const data = {comments: comments};
    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const addReply = (currentReplies, newReply) => dispatch => {
    const replies = [...currentReplies, newReply];
    const data = {replies: replies};
    dispatch(
        handleSuccessResponse(GET_REPLIES, data, 200)
    );
};

export const sendPosts = (currentPosts, data) => dispatch => {
    dispatch(
        loading(SEND_POSTS, {}, 0, true)
    );
    axios.post(`${FORUM_URL}/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            const clonedPosts = [...currentPosts];
            response.data.posts.forEach(newItem => {
                const filtered = clonedPosts.filter(item => item.pendingId === newItem.pendingId &&
                    localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    clonedPosts.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0) {
                            clonedPosts.splice(index, 1, newItem);
                        }
                    });
                }
            });
            const data = {posts: clonedPosts};
            dispatch(
                handleSuccessResponse(GET_POSTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_POSTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_POSTS, error)
                );
            }
        )
};

export const sendComments = (currentComments, data) => dispatch => {
    dispatch(
        loading(SEND_COMMENTS, {}, 0, true)
    );
    axios.post(`${FORUM_URL}/comment/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            const clonedComments = [...currentComments];
            response.data.comments.forEach(newItem => {
                const filtered = clonedComments.filter(item => item.pendingId === newItem.pendingId &&
                    localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    clonedComments.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0) {
                            clonedComments.splice(index, 1, newItem);
                        }
                    });
                }
            });
            const data = {comments: clonedComments};
            dispatch(
                handleSuccessResponse(GET_COMMENTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_COMMENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_COMMENTS, error)
                );
            }
        )
};

export const sendReplies = (currentReplies, data) => dispatch => {
    dispatch(
        loading(SEND_REPLIES, {}, 0, true)
    );
    axios.post(`${FORUM_URL}/reply/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            const clonedReplies = [...currentReplies];
            console.log(response.data.replies);
            response.data.replies.forEach(newItem => {
                const filtered = clonedReplies.filter(item => item.pendingId === newItem.pendingId &&
                    localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    clonedReplies.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            localStorage.getItem("teacherIdNo") === newItem.fromIdNo && item.id === 0) {
                            clonedReplies.splice(index, 1, newItem);
                        }
                    });
                }
            });
            const data = {replies: clonedReplies};
            dispatch(
                handleSuccessResponse(GET_REPLIES, data, 200)
            );
            dispatch(
                handleSuccessResponse(SEND_REPLIES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_REPLIES, error)
                );
            }
        )
};