import {DOWNLOAD_MARK_SHEET} from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false
};

export default (state = initialState, action) => {
    const newState = {...state};

    if (action.type === DOWNLOAD_MARK_SHEET) {
        newState.data = action.data;
        newState.status = action.status;
        newState.loading = action.loading;
        return newState;
    } else {
        return state;
    }
}
