import axios from '../axios/axios';
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";
import {GET_TIMETABLE} from "../constants/actionTypes";
import {TIMETABLE_URL} from "../constants/request_url";

export const getTimetable = () => dispatch => {
    dispatch(
        loading(GET_TIMETABLE, {}, 0, true)
    );
    axios.get(TIMETABLE_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_TIMETABLE, response.data, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(GET_TIMETABLE, error)
                );
            }
        )
};
