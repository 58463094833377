import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getNotices = (start, end) => dispatch => {
    dispatch(
        loading(actionTypes.GET_NOTICES, {}, 0, true)
    );

    axios.get(`${urls.NOTICE_URL}teacher/${start}/${end}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_NOTICES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_NOTICES, error)
                );
            }
        )
};

export const getNotice = id => dispatch => {
    dispatch(
        loading(actionTypes.GET_NOTICE, {}, 0, true)
    );
    if (typeof id !== 'number') {
        dispatch(
            loading(actionTypes.GET_NOTICE, {}, 404, false)
        );
    } else {
        axios.get(`${urls.NOTICE_URL}teacher/${id}`)
            .then(response => {
                dispatch(
                    handleSuccessResponse(actionTypes.GET_NOTICE, response.data, response.status)
                );
            })
            .catch(error => dispatch(
                handleErrorResponse(actionTypes.GET_NOTICE, error)
                )
            )
    }
};