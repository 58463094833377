export const LOGIN_URL = '/teacher/auth/login';
export const RESULTS_URL = '/result/';
export const VALIDATE_TOKEN_URL = '/teacher/auth/validate';
// export const BASE_URL = 'https://xently.xyz/doitonline';
// export const BASE_URL = 'https://192.168.100.2/do_it_online';
export const BASE_URL = 'https://files.deschule.com';
// export const BASE_URL = 'http://3.23.7.239/do_it_online';
export const PROFILE_IMG_URL = `${BASE_URL}/teacher_images`;
export const PARENT_IMG_URL = `${BASE_URL}/parent_images`;
export const SCHOOL_LOGO_URL = `${BASE_URL}/school_logos`;
export const HOMEWORK_IMG_URL = `${BASE_URL}/homework_images`;
export const LOGOUT_URL = '/teacher/auth/logout';
export const DOMAIN_NAME = 'localhost';
export const STUDENT_URL = '/student/';
export const TEACHER_URL = '/teacher/';
export const VALIDATE_TEACHER_ID_NO_URL = '/teacher/validate/id/';
export const ADMIN_URL = '/admin/';
export const FEES_STRUCTURE_URL = '/fees/structure/';
export const EVENT_URL = '/event/';
export const STREAM_URL = '/stream/';
export const EVENT_IMG_URL = `${BASE_URL}/event_images`;
export const SUBJECT_URL = '/subject/';
export const ATTENDANCE_URL = '/attendance/';
export const LEAVE_URL = '/leave/';
export const LEVEL_URL = '/level/teacher';
export const TERM_URL = '/term/teacher';
export const EXAM_URL = '/exam/teacher';
export const HOMEWORK_URL = '/homework/';
export const NOTICE_URL = '/notice/';
export const TIMETABLE_URL = '/timetable/teacher';
export const CHAT_URL = '/chat/teacher';
// export const REQUEST_BASE_URL = "https://do-it-online-api.herokuapp.com/api/v1";
// export const REQUEST_BASE_URL = "http://3.14.223.125:5001/api/v1";
// export const REQUEST_BASE_URL = "http://3.23.7.239:5001/api/v1";
// export const REQUEST_BASE_URL = "https://192.168.100.2:5001/api/v1";
export const REQUEST_BASE_URL = "https://api.deschule.com/api/v1";
// export const SOCKET_BASE_URL = "https://192.168.100.2:5001";
export const SOCKET_BASE_URL = "https://api.deschule.com";
export const FORUM_URL = '/forum';
