import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/es/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/es/Button/Button";
import {titleCase} from "../../helpers";
import {showError} from "../../actions/helperActions";
import {getStreams} from "../../actions/streamActions";
import {maxYear, minYear} from "../../constants/constants";
import Paper from "@material-ui/core/Paper";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {isWeekend} from "date-fns";
import {getAttendance, editAttendance} from "../../actions/attendanceActions";
import Checkbox from "@material-ui/core/Checkbox";
import {format} from 'date-fns';
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import {DatePicker} from "material-ui-pickers";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getClassLevels} from "../../actions/classLevelActions";

const styles = theme => ({});

const StyledFormControlLabel = withStyles({
    root: {
        height: 27
    },
    label: {
        textTransform: 'capitalize',
        color: "#FFF",
        fontSize: 15,
        fontFamily: "'Open Sans', 'sans-serif'",
    },
})(FormControlLabel);

class Attendance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classLevel: '',
            year: '',
            currentRow: null,
            openDialog: false,
            data: [],
            contentsChanged: false,
            editable: false,
            stream: '',
            date: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.shouldDisableDate = this.shouldDisableDate.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        document.title = "Attendance";
        this.props.getStreams();
        this.props.getClassLevels();
    }

    handleChange = event => {

    };

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                const {classLevel, year, stream, date} = this.state;
                if (classLevel !== '' && year !== '' && stream !== '' && date) {
                    const utcStringDate = date.toUTCString();
                    this.props.getAttendance(classLevel, year, stream, utcStringDate);
                }
            }
        );

    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        // this.props.getStudents();
    }

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {classLevel, year, stream, date} = this.state;
        const {dates} = this.props.data;
        if (classLevel !== '' && year !== '' && stream !== '' && date) {
            const data = [];

            this.state.data.forEach((function (item) {
                const {attendance, edited} = item;
                dates.forEach(_item => {
                    if (edited) {
                        if (attendance[_item]) {
                            data.push({
                                id: attendance[_item].id,
                                present: attendance[_item].present,
                            })
                        }
                    }
                });
            }));
            const submitData = JSON.stringify({
                data: data
            });
            this.props.editAttendance(submitData);
        } else {
            this.props.showError(true, 'please select all fields');
        }
    };

    handleModeChange = event => {
        const {attendances} = this.props.data;
        if (attendances.length > 0) {
            this.setState({
                    editable: event.target.value
                },
                () => {
                    if (this.state.editable) {
                        this.setState({
                            data: [...attendances]
                        })
                    } else {
                        this.setState({contentsChanged: false});
                        const {classLevel, year, stream, date} = this.state;
                        if (classLevel !== '' && year !== '' && stream !== '' && date) {
                            this.props.getAttendance(classLevel, year, stream, date.toUTCString());
                        }
                    }
                })
        }


    };

    handleDateChange = date => {
        this.setState({date},
            () => {
                const {classLevel, year, stream, date} = this.state;
                if (classLevel !== '' && year !== '' && stream !== '' && date) {
                    const utcStringDate = date.toUTCString();
                    this.props.getAttendance(classLevel, year, stream, utcStringDate);
                }
            });
    };

    shouldDisableDate = date => {
        return isWeekend(date);
    };

    render() {
        const {classLevel, category, editable} = this.state;
        const {data, status, streamsData, classes, editData, loading, levelsData} = this.props;
        const {attendances, dates} = data;
        const years = [];
        const currentYear = new Date().getFullYear();
        for (let i = minYear; i <= currentYear; i++) {
            years.push(i);
        }

        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="attendance"/>
                <div className="menu-drawer-h p-3 container">
                    <div className="d-flex justify-content-between">
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="mode">
                                Mode
                            </InputLabel>
                            <Select
                                value={editable}
                                input={<Input name="mode"/>}
                                displayEmpty
                                name="editable"
                                className={classes.selectEmpty}
                                onChange={this.handleModeChange}
                            >
                                <MenuItem value={false}>View</MenuItem>
                                <MenuItem value={true}>Editable</MenuItem>
                            </Select>
                        </FormControl>

                        {
                            this.state.contentsChanged && editable &&
                            <Button variant="contained"
                                    color="primary"
                                    size="small"
                                    type="submit"
                                    onClick={this.handleSubmit}>
                                Submit changes
                            </Button>
                        }

                    </div>
                    <div className='row'>
                        <div className="col-md-3 mb-sm-3">
                            {
                                !levelsData.levels &&
                                <FormControl className={classes.formControl}
                                             disabled={editable}>
                                    <InputLabel shrink htmlFor="level-label-placeholder">
                                        {titleCase(localStorage.getItem("teacherLevelName"))}
                                    </InputLabel>
                                    <Select
                                        value={classLevel}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="classLevel"
                                                      id="level-label-placeholder"/>}
                                        displayEmpty
                                        name="classLevel"
                                        className={classes.selectEmpty}
                                    >
                                        <MenuItem value=''>
                                            <CircularProgress color='primary' size={30}
                                                              thickness={5}/>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            {
                                levelsData.levels &&
                                <FormControl className={classes.formControl}
                                             disabled={editable}>
                                    <InputLabel shrink htmlFor="level-label-placeholder">
                                        {titleCase(localStorage.getItem("teacherLevelName"))}
                                    </InputLabel>
                                    <Select
                                        value={classLevel}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="classLevel"
                                                      id="level-label-placeholder"/>}
                                        displayEmpty
                                        name="classLevel"
                                        className={classes.selectEmpty}
                                    >
                                        <MenuItem
                                            value=''>{titleCase(localStorage.getItem("teacherLevelName"))}...</MenuItem>
                                        {
                                            levelsData.levels.map((item, index) => (
                                                <MenuItem value={item.id} key={index}>
                                                    {titleCase(item.name)}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            }
                        </div>
                        <div className="col-md-3 mb-sm-3">
                            <FormControl className={classes.formControl}
                                         disabled={editable}
                            >
                                <InputLabel shrink htmlFor="term-label-placeholder">
                                    Year
                                </InputLabel>
                                <Select
                                    value={this.state.year}
                                    onChange={this.handleSelectChange}
                                    input={<Input name="year" id="term-label-placeholder"/>}
                                    displayEmpty
                                    name="year"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value=''>Choose year...</MenuItem>
                                    {
                                        years.map(year => (
                                            <MenuItem value={year} key={year}>{year}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-3 mb-sm-3">
                            {
                                !streamsData.streams &&
                                <FormControl className={classes.formControl}
                                             disabled={editable}>
                                    <InputLabel shrink htmlFor="term-label-placeholder">
                                        Stream
                                    </InputLabel>
                                    <Select
                                        value={this.state.stream}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="stream"
                                                      id="term-label-placeholder"/>}
                                        displayEmpty
                                        name="stream"
                                        className={classes.selectEmpty}
                                    >
                                        <MenuItem value=''>Choose stream...</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            {
                                streamsData.streams &&
                                <FormControl className={classes.formControl}
                                             disabled={editable}
                                >
                                    <InputLabel shrink htmlFor="term-label-placeholder">
                                        Stream
                                    </InputLabel>
                                    <Select
                                        value={this.state.stream}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="stream"
                                                      id="term-label-placeholder"/>}
                                        displayEmpty
                                        name="stream"
                                        className={classes.selectEmpty}
                                    >
                                        <MenuItem value=''>Choose stream...</MenuItem>
                                        {
                                            streamsData.streams.map((stream, index) => (
                                                <MenuItem value={stream.value} key={index}>
                                                    {titleCase(stream.name)}
                                                </MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                            }
                        </div>

                        <div className="col-md-3 mb-sm-3">
                            <DatePicker
                                value={this.state.date}
                                onChange={this.handleDateChange}
                                leftArrowIcon={<ChevronLeftIcon/>}
                                rightArrowIcon={<ChevronRightIcon/>}
                                label="Date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.label
                                }}
                                disabled={editable}
                                InputProps={{
                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                className="mb-4"
                                format="dd MMM yyyy"
                                disableFuture
                                openToYearSelection
                                shouldDisableDate={this.shouldDisableDate}
                            />
                        </div>
                    </div>

                    {
                        loading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }

                    {
                        this.state.data && editable && this.state.data.length > 0 &&
                        <table className="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th/>
                                <th>ADM NO</th>
                                <th>NAME</th>
                                {
                                    dates.map((date, index) => (
                                        <th key={index}>
                                            {format(new Date(date), "EEE do MMM, YYYY", {awareOfUnicodeTokens: true})}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.data.map((attendance, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{attendance.admNo}</td>
                                        <td>{titleCase(attendance.name)}</td>
                                        {
                                            dates.map((date, _index) => (
                                                <td key={_index}>
                                                    {
                                                        attendance.attendance[date] &&
                                                        <div className="d-flex justify-content-center">
                                                            <StyledFormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={this.state.data[index]['attendance'][date].present}
                                                                        onChange={event => {
                                                                            const data = [...this.state.data];
                                                                            data[index]['attendance'][date].present = event.target.checked;
                                                                            data[index]['edited'] = true;

                                                                            this.setState({
                                                                                data: data,
                                                                                contentsChanged: true
                                                                            })
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    }

                                                    {
                                                        !attendance.attendance[date] &&
                                                        <div className="d-flex justify-content-center">_</div>
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    }

                    {
                        attendances && !editable && attendances.length > 0 && !loading &&
                        <table className="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th/>
                                <th>ADM NO</th>
                                <th>NAME</th>
                                {
                                    dates.map((date, index) => (
                                        <th key={index}>
                                            {format(new Date(date), "EEE do MMM, YYYY", {awareOfUnicodeTokens: true})}
                                        </th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                attendances.map((attendance, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{attendance.admNo}</td>
                                        <td>{titleCase(attendance.name)}</td>
                                        {
                                            dates.map((date, _index) => (
                                                <td key={_index}>
                                                    {
                                                        attendance.attendance[date] &&
                                                        <div className="d-flex justify-content-center">
                                                            {
                                                                attendance.attendance[date].present === true &&
                                                                <CheckIcon color="primary"/>
                                                            }
                                                            {
                                                                attendance.attendance[date].present === false &&
                                                                <CancelIcon color="error"/>
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        !attendance.attendance[date] &&
                                                        <div className="d-flex justify-content-center">_</div>
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    }

                    {
                        attendances &&
                        <div>
                            {
                                attendances.length === 0 &&
                                <Paper>
                                    <div className='p-3'>
                                        Attendance has not been marked.
                                    </div>
                                </Paper>
                            }
                        </div>
                    }

                    {
                        data.error && status !== 401 &&
                        <div>
                            {
                                data.error === true &&
                                <Paper>
                                    <div className='p-3'>
                                        {data.message}
                                    </div>
                                </Paper>
                            }
                        </div>
                    }

                </div>
            </MuiThemeProvider>
        );
    }
}

Attendance.propTypes = {
    getStreams: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    getAttendance: PropTypes.func.isRequired,
    editAttendance: PropTypes.func.isRequired,
    getClassLevels: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.getAttendanceData.data,
    errorData: state.showErrorData.data,
    streamsData: state.getStreamsData.data,
    status: state.getAttendanceData.status,
    loading: state.getAttendanceData.loading,
    levelsData: state.levelsData.data
});

export default connect(mapStateToProps, {
    getAttendance,
    getStreams,
    editAttendance,
    getClassLevels
})(withStyles(styles, {withTheme: true})(Attendance));