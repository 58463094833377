import * as ActionTypes from "../../constants/actionTypes";

const initialState = {
    data: {}
};

export default (state = initialState, action) => {
    const newState = {...state};

    switch (action.type) {
        case ActionTypes.SHOW_ERROR:
            newState.data = action.data;
            return newState;
        default:
            return state;
    }
}
