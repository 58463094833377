import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoginRoute = ({ component: Component, authError, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            authError
                ? <Component {...props} />
                : <Redirect to="/"/>
        )}
    />
);

export default LoginRoute;