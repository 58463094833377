import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import UserImgPlaceholder from "../../images/placeholders/user.jpg";
import {PARENT_TO_TEACHER, TEACHER_TO_PARENT} from "../../constants/constants";
import {PARENT_IMG_URL} from "../../constants/request_url";
import {formatLatestChatDate, trimString} from "../../helpers";
import TickIcon from "../../images/ic_tick.png";
import ClockIcon from '../../images/square_clock.png';
import PrimaryTickIcon from "../../images/ic_tick_primary.png";
import DoubleTickIcon from "../../images/ic_tick_double.png";

const styles = theme => ({
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 1),
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        position: 'absolute',
        pointerEvents: 'none',
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: 30,
        width: '100%'
    }
});

class LatestChats extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {classes, chats, currentIndex} = this.props;

        return (
            <div className='latest-chats-container scrollbar'>
                <div>
                    {
                        chats.map((item, index) => {
                            let username = '';
                            let idNo = '';
                            let imageUri = null;
                            if (item.category === PARENT_TO_TEACHER) {
                                username = item.from.name;
                                idNo = item.from.idNo;
                                imageUri = item.from.imageUri;
                            } else {
                                username = item.to.name;
                                idNo = item.to.idNo;
                                imageUri = item.to.imageUri;
                            }
                            return (
                                <div className='latest-chat-item' key={index} onClick={this.props.setCurrentIndex(index, idNo, username, imageUri)}
                                style={currentIndex === index ? {background: '#F3F3F3'} : {}}>
                                    {
                                        imageUri &&
                                            <img src={`${PARENT_IMG_URL}/${imageUri}`} alt="profile-image" className='latest-chat-img'/>
                                    }
                                    {
                                        !imageUri &&
                                        <img src={UserImgPlaceholder} alt="profile-image" className='latest-chat-img'/>
                                    }
                                    <div className='latest-chat-content'>
                                        <div className='latest-chat-inner-content'>
                                            <div>
                                                <div className='text-capitalize'>{trimString(username, 25)}</div>
                                                <div className='d-flex align-items-center'>
                                                    {
                                                        item.category === TEACHER_TO_PARENT && item.read &&
                                                        <img src={PrimaryTickIcon} alt="" className='latest-tick-icon'/>
                                                    }
                                                    {
                                                        item.category === TEACHER_TO_PARENT && item.read === false && item.dateReceived &&
                                                        <img src={DoubleTickIcon} alt="" className='latest-tick-icon'/>
                                                    }
                                                    {
                                                        item.category === TEACHER_TO_PARENT && item.read === false && !item.dateReceived && item.sent === true &&
                                                        <img src={TickIcon} alt="" className='latest-tick-icon'/>
                                                    }
                                                    {
                                                        item.category === TEACHER_TO_PARENT && item.sent === false &&
                                                        <img src={ClockIcon} alt="" className='latest-tick-icon'/>
                                                    }
                                                    <div className='text-black-50'>{trimString(item.message, 25)}</div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column align-items-end'>
                                                <div className='text-black-50'>{formatLatestChatDate(item.dateSent)}</div>
                                                {
                                                    item.unread > 0 &&
                                                    <div className='unread-count'>{item.unread}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className='latest-chat-divider'/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

LatestChats.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    chats: PropTypes.array.isRequired,
    setCurrentIndex: PropTypes.func.isRequired,
    currentIndex: PropTypes.number.isRequired
};

export default withStyles(styles, {withTheme: true})(LatestChats);