import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {showError} from "../../actions/helperActions";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {titleCase} from "../../helpers";
import {format, isAfter, isBefore, parse, startOfWeek} from "date-fns";
import {DatePicker} from "material-ui-pickers";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getHomework} from "../../actions/homeworkActions";
import {Link} from "react-router-dom";

const styles = theme => ({});

class Homework extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const start = startOfWeek(today, {weekStartsOn: 1});
        this.state = {
            end: new Date(),
            start: start
        };
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.shouldDisableDateEnd = this.shouldDisableDateEnd.bind(this);
        this.shouldDisableDateStart = this.shouldDisableDateStart.bind(this);
    }

    componentDidMount() {
        document.title = 'Homework';
        const {start, end} = this.state;
        if (start && end) {
            const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            this.props.getHomework(from, to);
        }
    }

    handleStartDateChange = date => {
        this.setState({start: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getHomework(from, to);
                }
            });
    };

    handleEndDateChange = date => {
        this.setState({end: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getHomework(from, to);
                }
            });
    };

    shouldDisableDateStart = date => {
        const {end} = this.state;
        return isAfter(date, end);
    };

    shouldDisableDateEnd = date => {
        const {start} = this.state;
        return isBefore(date, start);
    };

    render() {
        const {data, classes, loading} = this.props;
        const {start, end} = this.state;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="homework"/>
                <div className="menu-drawer-h p-3 container d-flex justify-content-center">
                    <div className='w-50'>
                        <div className='d-flex justify-content-start'>
                            <DatePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                leftArrowIcon={<ChevronLeftIcon/>}
                                rightArrowIcon={<ChevronRightIcon/>}
                                label="From"
                                InputLabelProps={{
                                    shrink: true,
                                    className: classes.label
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.resize
                                    }
                                }}
                                format="dd MMM yyyy"
                                disableFuture
                                openToYearSelection={false}
                                shouldDisableDate={this.shouldDisableDateStart}
                            />
                            <div className='ml-3'>
                                <DatePicker
                                    value={end}
                                    onChange={this.handleEndDateChange}
                                    leftArrowIcon={<ChevronLeftIcon/>}
                                    rightArrowIcon={<ChevronRightIcon/>}
                                    label="To"
                                    InputLabelProps={{
                                        shrink: true,
                                        className: classes.label
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: classes.resize
                                        }
                                    }}
                                    format="dd MMM yyyy"
                                    disableFuture
                                    openToYearSelection={false}
                                    shouldDisableDate={this.shouldDisableDateEnd}
                                />
                            </div>
                        </div>
                        <div className='mt-5'>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-1'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                data.data && data.data.length > 0 && !loading &&
                                <div>
                                    {
                                        data.data.map((item, index) => {
                                            const date = parse(item.date, "yyyy-MM-dd", new Date());
                                            const displayDate = format(date, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});
                                            const submissionDate = parse(item.submissionDate, "yyyy-MM-dd", new Date());
                                            const displaySubmissionDate = format(submissionDate, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});

                                            return (
                                                <Paper key={index} className='mb-3'>
                                                    <Link to={`/homework/${item.id}`} className='text-dark'>
                                                        <div className="p-3">
                                                            <div className='mt-2'>
                                                                <strong>Subject</strong>: <span>{titleCase(item.subject)}</span>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>{titleCase(localStorage.getItem("teacherLevelName"))}</strong>: <span>{titleCase(item.level)}</span>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>Stream</strong>: <span>{titleCase(item.stream)}</span>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>Date</strong>: <span>{displayDate}</span>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>Submission Date: </strong>
                                                                <span>{displaySubmissionDate}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Paper>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                data.data && data.data.length === 0 && !loading &&
                                <Paper>
                                    <div className='p-3'>
                                        You've not added any Homework between these dates
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Homework.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getHomework: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.homeworkData.data,
    status: state.homeworkData.status,
    loading: state.homeworkData.loading,
});

export default connect(mapStateToProps, {
    getHomework
})(withStyles(styles, {withTheme: true})(Homework));