import React, {Component} from 'react';
import Calendar from 'react-calendar';
import Paper from "@material-ui/core/Paper/Paper";
import CalendarIcon from '@material-ui/icons/Event';


class EventCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = date => this.setState({date});

    render() {
        return (
            <div>
                <Paper>
                    <div className="calendar-title font-lato"><CalendarIcon/>Event Calendar</div>
                    <Calendar
                        onChange={this.onChange}
                        value={this.state.date}
                        className="w-100 dashboard-calendar border-none"
                    />
                </Paper>
            </div>
        );
    }
}

EventCalendar.propTypes = {};

export default EventCalendar;
