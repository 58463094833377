import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getStreams = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_STREAMS, {}, 0, true)
    );
    axios.get(`${urls.STREAM_URL}teacher`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_STREAMS, response.data, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(actionTypes.GET_STREAMS, error)
                );
            }
        )
};