import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PaperComponent(props) {
    return (
        <Draggable cancel='.input'>
            <Paper {...props} />
        </Draggable>
    );
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
    },
}))(MuiDialogContent);


class AddPostDialog extends Component {

    render() {
        const {open, handleClose, post, handleChange, handleSubmit} = this.props;

        return (
            <Dialog open={open}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    fullWidth
                    PaperComponent={PaperComponent}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
            >
                <DialogTitle onClose={handleClose}>
                    Add Forum Post
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className=' mt-4 ml-3 mr-3'>
                        <textarea
                            value={post}
                            onChange={handleChange}
                            className='form-control input'
                            rows={10}/>
                        </div>
                        <Divider light={false} className='mt-4'/>
                        <div className='p-3 d-flex justify-content-end'>
                            <Button color="primary" type="submit" variant='contained'
                                    disabled={post.trim() === ''} onClick={handleSubmit}>
                                Post
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

AddPostDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    post: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles, {withTheme: true})(AddPostDialog);