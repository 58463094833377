import * as ActionTypes from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false
};

export default (state = initialState, action) => {
    // const newState = Object.assign({}, state);
    const newState = {...state};
    /*if (action.type === ActionTypes.GET_TOKEN) {
        newState.data = action.data;
        newState.status = action.status;
        newState.loading = action.loading;
        return newState;
    } else if (action.type === ActionTypes.GET_TOKEN_ERROR) {
        newState.data = action.data;
        newState.status = action.status;
        newState.loading = action.loading;
        return newState;
    } else {
        return state;
    }*/

    switch (action.type) {
        case ActionTypes.GET_TOKEN:
            newState.data = action.data;
            newState.status = action.status;
            newState.loading = action.loading;
            return newState;
        case ActionTypes.GET_TOKEN_ERROR:
            newState.data = action.data;
            newState.status = action.status;
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
