import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {fade} from "@material-ui/core/styles/colorManipulator";
import UserImgPlaceholder from "../../images/placeholders/user.jpg";
import {PARENT_TO_TEACHER, TEACHER_TO_PARENT} from "../../constants/constants";
import {PARENT_IMG_URL} from "../../constants/request_url";
import {formatChatGroupDate, formatLatestChatDate, trimString} from "../../helpers";
import TickIcon from "../../images/ic_tick.png";
import ClockIcon from '../../images/square_clock.png';
import PrimaryTickIcon from "../../images/ic_tick_primary.png";
import DoubleTickIcon from "../../images/ic_tick_double.png";
import Chip from "@material-ui/core/Chip";
import {Divider} from "@material-ui/core";
import ArrowBackIcon from '../../images/arrow_back_white.png';

const styles = theme => ({
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 1),
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        position: 'absolute',
        pointerEvents: 'none',
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: 30,
        width: '100%'
    },
    chipLabel: {
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        }
    },
    chipRoot: {
        [theme.breakpoints.down('md')]: {
            padding: 10,
            height: 'auto'
        }
    },
    chipOutlined: {
        [theme.breakpoints.down('md')]: {
            borderRadius: 50
        }
    }
});

class StudentContacts extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {classes, chats, currentIndex, slideOut, students} = this.props;

        return (
            <div className={`contacts-container animated ${slideOut ? 'slideOutLeft' : 'slideInLeft'} faster`}>
                <div className='contact-toolbar bg-primary-1'>
                    <img src={ArrowBackIcon} className='arrow-back' onClick={this.props.toggleContacts}/>
                    <div className='text-white ml-2'>New Chat</div>
                </div>
                <div className='contacts-inner-container scrollbar'>
                    {
                        students.map((item, index) => (
                            <div className='contact-item mt-3 text-capitalize' key={index}>
                                <div className=''>
                                    <div className='d-flex'>
                                        <div>{item.name} </div>
                                        <div className='text-black-50'> ({item.admNo})</div>
                                    </div>
                                    <div className='text-black-50'>{item.levelName} {item.streamName}</div>
                                </div>
                                {
                                    item.parents.length > 0 &&
                                    <div className='mt-2'>
                                        {
                                            item.parents.map((parent, parentIndex) => (
                                                <Chip label={parent.relationship}
                                                      variant="outlined"
                                                      color='primary'
                                                      className={parentIndex === 0 ? '' : 'ml-2'}
                                                      key={parentIndex}
                                                      onClick={this.props.switchParent(parent.idNo, parent.name, parent.imageUri)}
                                                      classes={{
                                                          label: classes.chipLabel,
                                                          root: classes.chipRoot,
                                                          outlined: classes.chipOutlined
                                                      }}
                                                />
                                            ))
                                        }
                                    </div>
                                }
                                <Divider
                                    className='mt-3'/>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

StudentContacts.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    chats: PropTypes.array.isRequired,
    switchParent: PropTypes.func.isRequired,
    currentIndex: PropTypes.number.isRequired,
    toggleContacts: PropTypes.func.isRequired,
    slideOut: PropTypes.bool.isRequired,
    students: PropTypes.array.isRequired,
};

export default withStyles(styles, {withTheme: true})(StudentContacts);