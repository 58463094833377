import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig} from "../constants/constants";
import {loading} from "./helperActions";

export const addHomework = (level, stream, subject, date, data) => dispatch => {
    dispatch(
        loading(actionTypes.ADD_HOMEWORK, {}, 0, true)
    );
    axios.post(`${urls.HOMEWORK_URL}${level}/${stream}/${subject}/${date}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.ADD_HOMEWORK, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.ADD_HOMEWORK, error, true)
                );
            }
        )
};

export const getHomework = (start, end) => dispatch => {
    dispatch(
        loading(actionTypes.GET_HOMEWORK, {}, 0, true)
    );
    axios.get(`${urls.HOMEWORK_URL}${start}/${end}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_HOMEWORK, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_HOMEWORK, error)
                );
            }
        )
};

export const getHomeworkQuestions = (itemId) => dispatch => {
    dispatch(
        loading(actionTypes.GET_HOMEWORK_QUESTIONS, {}, 0, true)
    );
    axios.get(`${urls.HOMEWORK_URL}${itemId}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_HOMEWORK_QUESTIONS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_HOMEWORK_QUESTIONS, error)
                );
            }
        )
};

export const addAssignment = (level, stream, subject, date, data) => dispatch => {
    dispatch(
        loading(actionTypes.ADD_HOMEWORK, {}, 0, true)
    );
    axios.post(`${urls.HOMEWORK_URL}assignment/${level}/${stream}/${subject}/${date}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.ADD_HOMEWORK, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.ADD_HOMEWORK, error, true)
                );
            }
        )
};

export const getAssignments = (start, end) => dispatch => {
    dispatch(
        loading(actionTypes.GET_HOMEWORK, {}, 0, true)
    );
    axios.get(`${urls.HOMEWORK_URL}assignment/${start}/${end}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_HOMEWORK, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_HOMEWORK, error)
                );
            }
        )
};