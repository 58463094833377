import {GET_ALL_EVENTS} from "../../constants/actionTypes";

const initial_state = {
    data: {}
};

export default (state = initial_state, action) => {
    const newState = {...state};

    switch (action.type) {
        case GET_ALL_EVENTS:
            newState.data = action.data;
            newState.status = action.status;
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}