import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import {titleCase} from "../../helpers";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PaperComponent(props) {
    return (
        <Draggable>
            <Paper {...props} />
        </Draggable>
    );
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);


class ResultDialog extends Component {
    render() {
        const {data, open, handleClose} = this.props;
        return (
            <Dialog open={open}
                    TransitionComponent={Transition}
                    maxWidth="md"
                    fullWidth
                    PaperComponent={PaperComponent}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
            >
                <DialogTitle onClose={handleClose}>
                    Student Result
                </DialogTitle>
                <DialogContent>
                    {
                        data &&
                        <div className="result-dialog">
                            <div className="w-100 d-flex">
                                <div className="w-50 text-right no-wordwrap">ADMISSION NUMBER:</div>
                                <div className="w-50 text-left pl-3">{data.admNo}</div>
                            </div>
                            <div className="w-100 d-flex">
                                <div className="w-50 text-right">NAME:</div>
                                <div className="w-50 text-left pl-3">{titleCase(data.fullName)}</div>
                            </div>
                            <div className="w-100 d-flex">
                                <div className="w-50 text-right">STREAM:</div>
                                <div className="w-50 text-left pl-3">{data.stream.streamName}</div>
                            </div>
                            {
                                data.subjects.map((subject, index) => (
                                    <div className="w-100 d-flex" key={index}>
                                        <div className="w-50 text-right">{subject.subjectName.toUpperCase()}:</div>
                                        <div className="w-50 text-left pl-3">
                                            {subject.score}
                                            {
                                                subject.grade &&
                                                <span className='ml-2'>
                                                        {subject.grade.toUpperCase()}
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                data.totalPoints &&
                                <div className="w-100 d-flex">
                                    <div className="w-50 text-right">TOTAL POINTS:</div>
                                    <div className="w-50 text-left pl-3">{data.totalPoints}</div>
                                </div>
                            }
                            {
                                data.meanGrade &&
                                <div className="w-100 d-flex">
                                    <div className="w-50 text-right">MEAN GRADE:</div>
                                    <div className="w-50 text-left pl-3">{data.meanGrade}</div>
                                </div>
                            }
                            <div className="w-100 d-flex">
                                <div className="w-50 text-right">STREAM POSITION:</div>
                                <div className="w-50 text-left pl-3">{data.streamPosition}</div>
                            </div>
                            < div className="w-100 d-flex">
                                <div className="w-50 text-right">OVERALL POSITION:</div>
                                <div className="w-50 text-left pl-3">{data.position}</div>
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

ResultDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ResultDialog);