import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide/Slide";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {getTeachingSubjects} from "../../actions/subjectActions";
import {getStreams} from "../../actions/streamActions";
import {getStreamStudents} from "../../actions/studentActions";
import {titleCase, toastError} from "../../helpers";
import {addResults} from "../../actions/resultActions";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {WhiteCircularProgress} from "../progress_bars/ProgressBars";
import {getClassLevels} from "../../actions/classLevelActions";
import {getExams} from "../../actions/examActions";
import {getTerms} from "../../actions/termsActions";


const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddResultDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            classLevel: '',
            year: new Date().getFullYear(),
            exam: '',
            term: '',
            subject: '',
            stream: '',
            data: [],
        }
        ;
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        this.props.getTeachingSubjects();
        this.props.getStreams();
        this.props.getClassLevels();
        this.props.getTerms();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {examsData} = this.props;
        const {classLevel, year, stream, exam, term, subject} = this.state;
        if (classLevel !== '' && year !== '' && stream !== '' && exam !== '' && term !== '' && subject !== '') {
            let empty = false;
            const {exams} = examsData;
            let maximum = null;
            let scoreExceeds = false;

            exams.forEach(item => {
                if (item.id === exam) {
                    maximum = item.maximum;
                }
            });

            if (this.state.data.length < 1) {
                empty = true;
            }

            this.state.data.forEach((function (item) {
                if (item.score === '') {
                    empty = true;
                } else if (item.score < 0 || item.score > maximum) {
                    scoreExceeds = true;
                }
            }));

            if (empty === true) {
                toastError('please fill all fields');
            } else if (scoreExceeds) {
                toastError(`Score must be between 0 to ${maximum}`);
            } else {
                const data = JSON.stringify({
                    data: this.state.data
                });
                this.props.addResults(data, classLevel, year, term, exam, subject);
            }
        } else {
            toastError('please select all fields');
        }
    };

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                if (event.target.name === 'classLevel' || event.target.name === 'year' ||
                    event.target.name === 'stream') {
                    const {classLevel, year, stream} = this.state;
                    if (classLevel !== '' && year !== '' && stream !== '') {
                        this.setState({
                            data: []
                        });
                        this.props.getStreamStudents(classLevel, year, stream);
                    }
                }

                if (event.target.name === 'classLevel' || event.target.name === 'year'
                    || event.target.name === 'term') {
                    const {classLevel, year, term} = this.state;
                    if (classLevel !== '' && year !== '' && term !== '') {
                        this.props.getExams(classLevel, term, year);
                    }
                }
            }
        );

    };

    render() {
        const {
            classes, subjectsData, streamsData, studentsData, loading, examsData, levelsData,
            termsData
        } = this.props;
        const {classLevel, year, stream, exam, term, subject} = this.state;
        const currentYear = new Date().getFullYear();

        const years = [];

        for (let i = currentYear; i < 2100; i++) {
            years.push(i)
        }

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <div className="container mb-3">
                        <div className="row clearfix">
                            <div className="col-md-12 column">
                                <form onSubmit={this.handleSubmit}>
                                    <AppBar className={classes.appBar}>
                                        <Toolbar>
                                            <IconButton color="inherit" onClick={this.props.handleClose}
                                                        aria-label="Close">
                                                <CloseIcon/>
                                            </IconButton>
                                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                                Add Results
                                            </Typography>
                                            <Button color="inherit" type="submit"
                                                    disabled={!(classLevel !== '' && year !== '' && stream !== '' &&
                                                        exam !== '' && term !== '' && subject !== '')}>
                                                {
                                                    !loading &&
                                                    <span>add</span>
                                                }
                                                {
                                                    loading &&
                                                    <WhiteCircularProgress size={30} thickness={5}/>
                                                }
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                    <div className="container"
                                         style={{marginTop: 90}}>
                                        <div className="row">
                                            <div className="col-md-3 mb-sm-3">
                                                {
                                                    !levelsData.levels &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(localStorage.getItem("teacherLevelName"))}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    levelsData.levels &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(localStorage.getItem("teacherLevelName"))}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem
                                                                value=''>{titleCase(localStorage.getItem("teacherLevelName"))}...</MenuItem>
                                                            {
                                                                levelsData.levels.map((item, index) => (
                                                                    <MenuItem value={item.id} key={index}>
                                                                        {titleCase(item.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-3 mb-sm-3">
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel shrink htmlFor="year-label-placeholder">
                                                        Year
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.year}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="year" id="year-label-placeholder"/>}
                                                        displayEmpty
                                                        name="year"
                                                        className={classes.selectEmpty}
                                                    >
                                                        {
                                                            years.map((item, index) => (
                                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-md-3 mb-sm-3">
                                                {
                                                    !streamsData.streams &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                                            Stream
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.stream}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="stream-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    streamsData.streams &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                                            Stream
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.stream}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="stream-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>Choose stream...</MenuItem>
                                                            {
                                                                streamsData.streams.map((stream, index) => (
                                                                    <MenuItem value={stream.value} key={index}>
                                                                        {titleCase(stream.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-3 mb-sm-3">
                                                {
                                                    !termsData.terms &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                                            {titleCase(localStorage.getItem("teacherTermName"))}
                                                        </InputLabel>
                                                        <Select
                                                            value={term}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="term"
                                                                          id="term-label-placeholder"/>}
                                                            displayEmpty
                                                            name="term"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    termsData.terms &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                                            {titleCase(localStorage.getItem("teacherTermName"))}
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.term}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="term"
                                                                          id="term-label-placeholder"/>}
                                                            displayEmpty
                                                            name="term"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem
                                                                value=''>{titleCase(localStorage.getItem("teacherTermName"))}...</MenuItem>
                                                            {
                                                                termsData.terms.map((item, index) => (
                                                                    <MenuItem value={item.id} key={index}>
                                                                        {titleCase(item.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-3 mb-sm-3">
                                                {
                                                    !examsData.exams &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="exam-label-placeholder">
                                                            Exam
                                                        </InputLabel>
                                                        <Select
                                                            value={exam}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="exam"
                                                                          id="exam-label-placeholder"/>}
                                                            displayEmpty
                                                            name="exam"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>Exam...</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    examsData.exams &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="exam-label-placeholder">
                                                            Exam
                                                        </InputLabel>
                                                        <Select
                                                            value={exam}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="exam"
                                                                          id="exam-label-placeholder"/>}
                                                            displayEmpty
                                                            name="exam"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>Exam...</MenuItem>
                                                            {
                                                                examsData.exams.map((item, index) => {
                                                                    if (!item.average)
                                                                        return (
                                                                            <MenuItem value={item.id} key={index}>
                                                                                {titleCase(item.name)}
                                                                            </MenuItem>
                                                                        )
                                                                })
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-3 mb-sm-3">
                                                {
                                                    !subjectsData.subjects &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                                            Subject
                                                        </InputLabel>
                                                        <Select
                                                            value={subject}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="subject"
                                                                          id="subject-label-placeholder"/>}
                                                            displayEmpty
                                                            name="subject"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    subjectsData.subjects &&
                                                    <FormControl className={classes.formControl} fullWidth>
                                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                                            Subject
                                                        </InputLabel>
                                                        <Select
                                                            value={subject}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="subject"
                                                                          id="subject-label-placeholder"/>}
                                                            displayEmpty
                                                            name="subject"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>Choose subject...</MenuItem>
                                                            {
                                                                subjectsData.subjects
                                                                    .map((subject, index) => (
                                                                        <MenuItem value={subject.id} key={index}>
                                                                            {titleCase(subject.name)}
                                                                        </MenuItem>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="w-100">
                                                {
                                                    studentsData.students &&
                                                    <div>
                                                        {
                                                            studentsData.students.length === 0 &&
                                                            <Paper>
                                                                <div className='p-3'>
                                                                    There is no students in that stream
                                                                </div>
                                                            </Paper>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    studentsData.students &&
                                                    <div>
                                                        {
                                                            studentsData.students.length > 0 &&
                                                            <table className="table table-bordered table-hover">
                                                                <thead>
                                                                <tr>
                                                                    <th/>
                                                                    <th>ADM NO</th>
                                                                    <th>NAME</th>
                                                                    <th>SCORE</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    studentsData.students.map((student, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{student.admNo}</td>
                                                                            <td>
                                                                                {titleCase(`${student.fName}
                                                                         ${student.lName} ${student.surname}`)}
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    fullWidth
                                                                                    type='number'
                                                                                    value={this.state.data.score}
                                                                                    onChange={event => {
                                                                                        const {data} = this.state;
                                                                                        data[index].score = parseInt(event.target.value, 10);
                                                                                        this.setState({
                                                                                            data: data
                                                                                        });
                                                                                    }}
                                                                                    onFocus={e => {
                                                                                        if (this.state.data.length === 0) {
                                                                                            const data = [];
                                                                                            studentsData.students.forEach(function (item) {
                                                                                                data.push({
                                                                                                    admNo: item.admNo,
                                                                                                    score: ''
                                                                                                })
                                                                                            });
                                                                                            this.setState({
                                                                                                data: data
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        )
            ;
    }
}

AddResultDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    getTeachingSubjects: PropTypes.func.isRequired,
    getStreams: PropTypes.func.isRequired,
    getStreamStudents: PropTypes.func.isRequired,
    addResults: PropTypes.func.isRequired,
    getClassLevels: PropTypes.func.isRequired,
    getExams: PropTypes.func.isRequired,
    getTerms: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.addResultsData.data,
    streamsData: state.getStreamsData.data,
    subjectsData: state.getTeachingSubjectsData.data,
    studentsData: state.getStreamStudentsData.data,
    levelsData: state.levelsData.data,
    termsData: state.termsData.data,
    examsData: state.examsData.data,
    status: state.addResultsData.status,
    loading: state.addResultsData.loading,
});

export default connect(mapStateToProps, {
    addResults,
    getTeachingSubjects,
    getStreams,
    getStreamStudents,
    getClassLevels,
    getExams,
    getTerms
})(withStyles(styles)(AddResultDialog));