import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {logout} from "../../common";

class AuthErrorDialog extends Component {
    constructor(props) {
        super(props);
        this.redirectToLogin = this.redirectToLogin.bind(this);
    }

    redirectToLogin = e => {
        logout();
    };

    render() {
        const {open} = this.props;
        return (
            <Dialog open={open} fullWidth maxWidth='xs'>
                <div className='p-4'>
                    <div style={{fontSize: 20}} className='font-lato'>
                        Your session has expired. Please login again.
                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <Button variant='text' onClick={this.redirectToLogin}>
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

AuthErrorDialog.propTypes = {
    open: PropTypes.bool.isRequired
};

export default AuthErrorDialog;