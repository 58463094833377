import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import Paper from "@material-ui/core/Paper";

class Overview extends Component {
    render() {
        return (
            <div>
                <div className="d-flex flex-column">
                    <div className="mb-3">
                        <Paper>
                            <div className="overview-item d-flex">
                                <div className="d-flex justify-content-center align-items-center w-25 bg-1">
                                    <i className="fa fa-users text-white overview-icon"/>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around overview-content bg-gray w-75">
                                    <h6>Active Students</h6>
                                    <h5>1298</h5>
                                    <Link to="/student" className="link-color">View <i
                                        className="fa fa-sign-out"/></Link>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div className="mb-3">
                        <Paper>
                            <div className="overview-item d-flex">
                                <div className="d-flex justify-content-center align-items-center w-25 bg-2">
                                    <i className="fa fa-user text-white overview-icon"/>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around overview-content bg-gray w-75">
                                    <h6>Active Teachers</h6>
                                    <h5>38</h5>
                                    <Link to="/teacher" className="link-color">View <i
                                        className="fa fa-sign-out"/></Link>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div className="mb-3">
                        <Paper>
                            <div className="overview-item d-flex">
                                <div className="d-flex justify-content-center align-items-center w-25 bg-3">
                                    <i className="fa fa-user-secret text-white overview-icon"/>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around overview-content bg-gray w-75">
                                    <h6>Active Admins</h6>
                                    <h5>5</h5>
                                    <Link to="/admin" className="link-color">View <i className="fa fa-sign-out"/></Link>
                                </div>
                            </div>
                        </Paper>
                    </div>

                    <div className="mb-3">
                        <Paper fullwidth='true'>
                            <div className="overview-item d-flex">
                                <div className="d-flex justify-content-center align-items-center w-25 bg-4">
                                    <i className="fa fa-user-secret text-white overview-icon"/>
                                </div>
                                <div
                                    className="d-flex flex-column justify-content-around overview-content bg-gray w-75">
                                    <h6>Active Admins</h6>
                                    <h5>5</h5>
                                    <Link to="/admin" className="link-color">View <i className="fa fa-sign-out"/></Link>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}

Overview.propTypes = {};

export default Overview;