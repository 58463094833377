import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../../themes/Themes";
import withStyles from "@material-ui/core/styles/withStyles";

const style = theme => ({});

class Preloader extends Component {
    render() {
        return (
            <MuiThemeProvider theme={mainTheme}>
                    <div className="pre-loader">
                        <div className='d-flex flex-column align-items-center'>
                            <h1 className='color-primary font-lato'>Do It Online</h1>
                            <CircularProgress color='primary' size={80} thickness={4}/>
                        </div>
                    </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style, {withTheme: true})(Preloader);