import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {issueLeave} from "../../actions/leaveActions";
import {connect} from "react-redux";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import IssueLeaveForm from "../forms/IssueLeaveForm";

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class IssueLeaveDialog extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    submit = values => {
        const data = JSON.stringify({
            title: values.title,
            description: values.description,
            date: values.date.toUTCString()
        });
        this.props.issueLeave(data, parseInt(values.admNo, 10))
    };

    render() {
        const {data, closeDialog, open, handleClose, loading} = this.props;
        let err = false;
        let success = false;
        let errorMessage = '';
        let successMessage = '';

        if (data.error === false) {
            err = false;
            success = true;
            successMessage = this.props.data.message;

        } else if (data.error) {
            err = true;
            success = false;
            errorMessage = this.props.data.message;
        }
        return (
            <Dialog
                fullScreen
                open={open}
                onClose={closeDialog}
                TransitionComponent={Transition}
            >
                <IssueLeaveForm onSubmit={this.submit}
                                err={err}
                                success={success}
                                errMes={errorMessage}
                                successMessage={successMessage}
                                handleClose={handleClose}
                                loading={loading}/>
            </Dialog>
        );
    }
}

IssueLeaveDialog.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    issueLeave: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
    data: state.issueLeaveData.data,
    status: state.issueLeaveData.status,
    loading: state.issueLeaveData.loading,
});

export default connect(mapStateToProps, {issueLeave})(withStyles(styles, {withTheme: true})(IssueLeaveDialog));