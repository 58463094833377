import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {axiosConfig} from "../constants/constants";
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import * as actionTypes from "../constants/actionTypes";
import {loading} from "./helperActions";

export const issueLeave = (data, admNo) => dispatch => {
    dispatch(
        loading(actionTypes.ISSUE_LEAVE, {}, 0, true)
    );
    axios.post(`${urls.LEAVE_URL}${admNo}`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.ISSUE_LEAVE, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.ISSUE_LEAVE, error, true)
                );
            }
        )
};

export const editIssuedLeaves = (data) => dispatch => {
    dispatch(
        loading(actionTypes.EDIT_ISSUED_LEAVES, {}, 0, true)
    );
    axios.put(urls.LEAVE_URL, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.EDIT_ISSUED_LEAVES, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.EDIT_ISSUED_LEAVES, error, true)
                );
            }
        )
};

export const getIssuedLeaves = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_ISSUED_LEAVES, {}, 0, true)
    );
    axios.get(urls.LEAVE_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_ISSUED_LEAVES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_ISSUED_LEAVES, error)
                );
            }
        )
};