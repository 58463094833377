import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider/Divider';
import {Link} from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlusIcon from '@material-ui/icons/Add';
import ScheduleIcon from "@material-ui/icons/Schedule";
import ScheduleAltIcon from "@material-ui/icons/ScheduleTwoTone";
import ChatIcon from "@material-ui/icons/Chat";
import ForumIcon from "@material-ui/icons/Forum";
import ViewListIcon from "@material-ui/icons/ViewList";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CheckIcon from "@material-ui/icons/Check";
import SubjectIcon from "@material-ui/icons/Subject";
import AddResultDialog from "../dialogs/AddResultDialog";
import EventIcon from '@material-ui/icons/Event'
import MarkAttendanceDialog from "../dialogs/MarkAttendanceDialog";
import IssueLeaveDialog from "../dialogs/IssueLeaveDialog";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import AddHomeworkDialog from "../dialogs/AddHomeworkDialog";
import AddAssignmentDialog from "../dialogs/AddAssignmentDialog";

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub_menu: {
                attendance: false,
                leave: false,
                result: false,
                homework: false
            },
            open: false,
            addResult: false,
            markAttendance: false,
            issueLeave: false,
            addHomework: false
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    handleOpen = name => e => {
        if (!this.state.sub_menu[name]) {
            const {sub_menu} = this.state;
            const keys = Object.keys(sub_menu);

            keys.forEach(key => {
                if (sub_menu[key])
                    this.setState({
                        sub_menu: {
                            [key]: false
                        }
                    })
            });
        }


        this.setState(state => ({
            sub_menu: {
                [name]: !state.sub_menu[name]
            }
        }));

    };

    openDialog = name => e => {
        this.setState({[name]: true});
    };

    closeDialog = name => e => {
        this.setState({[name]: false});
        this.props.closeDrawer();
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <List
                    component="nav"
                >
                    <div>
                        <div onClick={this.handleOpen('result')} className="result">
                            <ListItem button>
                                <ListItemIcon>
                                    <ListAltIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText inset primary="Subject Results"/>
                                {this.state.sub_menu.result ? <ExpandLess className='menu-list-icon'/> : <ExpandMore className='menu-list-icon'/>}
                            </ListItem>
                        </div>
                        <Collapse in={this.state.sub_menu.result} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={this.openDialog('addResult')}>
                                    <ListItemIcon>
                                        <PlusIcon className='menu-list-icon'/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Add Results"/>
                                </ListItem>
                                <Link to="/result">
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <i className="fa fa-eye menu-list-icon"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary="View Results"/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </div>

                    <Link to='/overall'>
                        <ListItem button>
                            <ListItemIcon>
                                <ListIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Overall Results"/>
                        </ListItem>
                    </Link>

                    <div>
                        <div onClick={this.handleOpen('attendance')} className="attendance">
                            <ListItem button>
                                <ListItemIcon>
                                    <ViewListIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText inset primary="Attendance"/>
                                {this.state.sub_menu.attendance ? <ExpandLess className='menu-list-icon'/> : <ExpandMore className='menu-list-icon'/>}
                            </ListItem>
                        </div>
                        <Collapse in={this.state.sub_menu.attendance} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested}
                                          onClick={this.openDialog("markAttendance")}>
                                    <ListItemIcon>
                                        <CheckIcon className='menu-list-icon'/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Mark Attendance"/>
                                </ListItem>
                                <Link to="/attendance">
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <i className="fa fa-eye menu-list-icon"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary="View Attendance"/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </div>

                    <div>
                        <div onClick={this.handleOpen('leave')} className="leave">
                            <ListItem button>
                                <ListItemIcon>
                                    <ReceiptIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText inset primary="Leaves"/>
                                {this.state.sub_menu.leave ? <ExpandLess className='menu-list-icon'/> : <ExpandMore className='menu-list-icon'/>}
                            </ListItem>
                        </div>
                        <Collapse in={this.state.sub_menu.leave} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={this.openDialog('issueLeave')}>
                                    <ListItemIcon>
                                        <PlusIcon className='menu-list-icon'/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Issue Leave"/>
                                </ListItem>
                                <Link to="/leave">
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <i className="fa fa-eye menu-list-icon"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary="View Issued Leaves"/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </div>

                    <Link to='/timetable'>
                        <ListItem button>
                            <ListItemIcon>
                                <ScheduleIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="My Timetable"/>
                        </ListItem>
                    </Link>

                    {/*<Link to="/exam">
                        <ListItem button>
                            <ListItemIcon>
                                <ScheduleAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Exam Timetable"/>
                        </ListItem>
                    </Link>*/}

                    <Link to="/subject">
                        <ListItem button>
                            <ListItemIcon>
                                <SubjectIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="My Subjects"/>
                        </ListItem>
                    </Link>

                    <Link to="/">
                        <ListItem button>
                            <ListItemIcon>
                                <EventIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Events"/>
                        </ListItem>
                    </Link>

                    <Link to="/chat">
                        <ListItem button>
                            <ListItemIcon>
                                <ChatIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Chat"/>
                        </ListItem>
                    </Link>

                    <Link to="/forum">
                        <ListItem button>
                            <ListItemIcon>
                                <ForumIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary="Forum"/>
                        </ListItem>
                    </Link>

                    <Link to="/notice">
                        <ListItem button>
                            <ListItemIcon>
                                <Icon className={classNames('fa fa-bell-o menu-list-icon')}/>
                            </ListItemIcon>
                            <ListItemText primary="Notice Board"/>
                        </ListItem>
                    </Link>

                    <div>
                        <div onClick={this.handleOpen('homework')} className="homework">
                            <ListItem button>
                                <ListItemIcon>
                                   <Icon className={classNames('fa fa-file-o fa- menu-list-icon')}/>
                                </ListItemIcon>
                                <ListItemText inset primary="Homework"/>
                                {this.state.sub_menu.homework ? <ExpandLess className='menu-list-icon'/> : <ExpandMore className='menu-list-icon'/>}
                            </ListItem>
                        </div>
                        <Collapse in={this.state.sub_menu.homework} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested} onClick={this.openDialog('addHomework')}>
                                    <ListItemIcon>
                                        <PlusIcon className='menu-list-icon'/>
                                    </ListItemIcon>
                                    <ListItemText inset primary="Add Homework"/>
                                </ListItem>
                                <Link to="/homework">
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <i className="fa fa-eye menu-list-icon"/>
                                        </ListItemIcon>
                                        <ListItemText inset primary="View Homework"/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </div>

                    <Divider/>
                </List>

                <AddResultDialog open={this.state.addResult} handleClose={this.closeDialog('addResult')}/>
                <MarkAttendanceDialog open={this.state.markAttendance}
                                      handleClose={this.closeDialog('markAttendance')}/>
                <IssueLeaveDialog open={this.state.issueLeave}
                                      handleClose={this.closeDialog('issueLeave')}/>
                <AddAssignmentDialog open={this.state.addHomework}
                                      handleClose={this.closeDialog('addHomework')}/>

            </div>
        )
            ;
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    closeDrawer: PropTypes.func.isRequired,
};

export default withStyles(styles)(Menu);