import axios from 'axios';
import {REQUEST_BASE_URL} from "../constants/request_url";

const instance  = axios.create({
    baseURL: REQUEST_BASE_URL
});

instance.defaults.headers.common['Authorization'] = `bearer  ${localStorage.getItem('teacherToken')}`;

export default instance;
