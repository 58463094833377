import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar/AppBar";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import Drawer from "@material-ui/core/Drawer/Drawer";
import Divider from "@material-ui/core/Divider/Divider";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Menu from "./Menu";
import {withStyles} from "@material-ui/core";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import Paper from "@material-ui/core/Paper/Paper";
import {PROFILE_IMG_URL, SCHOOL_LOGO_URL} from '../../constants/request_url';

import {blacklistToken} from '../../actions/authActions';
import {connect} from "react-redux";
import UserPlaceHolderImg from '../../images/placeholders/user.jpg';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {logout} from "../../common";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {ToastContainer} from "react-toastify";

const drawerWidth = 300;
const drawerWidthSm = 700;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: '#FFF',
        height: 65,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: 90
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuIconRoot: {
        [theme.breakpoints.down('md')]: {
            height: 90,
            width: 90
        }
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('md')]: {
            width: 700
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        background: '#6699CC',
    },
    drawerToolbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        background: '#6699CC',
        height: 65,
        [theme.breakpoints.down('md')]: {
            height: 90
        }
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        maxWidth: 400,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'calc(100% - 20px)',
            marginTop: '25px'
        }
    },
});

class MenuDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            arrowRef: null,
            popAccountMenu: false,
        };
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleAccountClick = this.handleAccountClick.bind(this);
        this.handleArrowRef = this.handleArrowRef.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleAccountClickAway = this.handleAccountClickAway.bind(this);
    }

    componentDidUpdate() {

    }

    handleDrawerOpen() {
        this.setState({
            open: true
        });
    };

    handleDrawerClose() {
        this.setState({
            open: false
        });
    };

    handleAccountClick() {
        this.setState(state => ({
            popAccountMenu: !state.popAccountMenu,
        }));
    };

    handleArrowRef(node) {
        this.setState({
            arrowRef: node,
        });
    };

    handleLogout() {
        this.props.blacklistToken();
    }

    handleAccountClickAway = () => {
        this.setState({
            popAccountMenu: false
        })
    };

    render() {
        const {classes, theme} = this.props;
        const {popAccountMenu, arrowRef} = this.state;
        const id = popAccountMenu ? 'scroll-playground' : null;

        if (this.props.logoutData.error === false) {
            logout();
        }
        return (
            <div>
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    elevation={2}
                >
                    <Toolbar disableGutters={!this.state.open} className={classes.toolbar} style={{minHeight: 65}}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, this.state.open && classes.hide)}
                            classes={{
                                root: classes.menuIconRoot
                            }}
                        >
                            <MenuIcon className='menu-list-icon'/>
                        </IconButton>
                        <Typography color="inherit" noWrap gutterBottom variant="h6" className='m-0'>
                            {this.props.title.toUpperCase()}
                        </Typography>
                        <div className="d-inline-flex ml-auto">
                            <Button className="mr-3 account-menu p-1" onClick={this.handleAccountClick}
                                    buttonRef={node => {
                                        this.anchorEl = node;
                                    }}
                            >
                                {
                                    localStorage.getItem('teacherImageUri') === 'null' ?
                                        <img
                                            src={UserPlaceHolderImg}
                                            alt="profile photo" className="toolbar-account-img"/>
                                        :
                                        <img
                                            src={`${PROFILE_IMG_URL}/${localStorage.getItem('teacherImageUri')}`}
                                            alt="profile photo" className="toolbar-account-img"/>
                                }

                                <span className="text-uppercase ml-1 text-white">
                                    {localStorage.getItem('teacherFName')}
                                </span>

                            </Button>
                            {/*<div className="mr-2">
                                <Link to='/'>
                                    <Button style={{color: '#FFF'}}>
                                        <span className="mr-2">Dashboard</span>
                                        <HomeIcon/>
                                    </Button>
                                </Link>
                            </div>*/}
                        </div>
                    </Toolbar>

                </AppBar>
                <Popper
                    id={id}
                    open={popAccountMenu}
                    anchorEl={this.anchorEl}
                    placement='bottom-end'
                    disablePortal={false}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        arrow: {
                            enabled: true,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'scrollParent'
                        },
                    }}
                >
                    <ClickAwayListener onClickAway={this.handleAccountClickAway}>
                        <div>
                            <span className={classes.arrow} ref={this.handleArrowRef}/>
                            <Paper className={classes.paper}>

                                <div className="d-flex p-3">
                                    {
                                        localStorage.getItem('teacherImageUri') === 'null' ?
                                            <img
                                                src={UserPlaceHolderImg}
                                                alt="profile photo" className="toolbar-account-img-md"/>
                                            :
                                            <img
                                                src={`${PROFILE_IMG_URL}/${localStorage.getItem('teacherImageUri')}`}
                                                alt="profile photo" className="toolbar-account-img-md"/>
                                    }

                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="text-uppercase ml-3 text-dark">
                                            {localStorage.getItem('teacherFName')}
                                            {` ${localStorage.getItem('teacherLName')} `}
                                            {localStorage.getItem('teacherSurname')}
                                        </div>
                                        <div className="ml-3">{localStorage.getItem('teacherIdNo')}</div>
                                        <Link to="/account"><Button className="text-info">Account Info</Button></Link>
                                    </div>
                                </div>
                                <Divider/>
                                <Button fullWidth onClick={this.handleLogout}>Logout<i
                                    className="fa fa-sign-out ml-1"/></Button>
                            </Paper>
                        </div>
                    </ClickAwayListener>
                </Popper>
                <Drawer
                    variant="temporary"
                    classes={{
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                    ModalProps={{onBackdropClick: this.handleDrawerClose}}
                >
                    <div className={classes.drawerToolbar}>

                        <div className="mr-auto d-flex align-items-center">
                            {/*<img
                                src={`${PROFILE_IMG_URL}/${cookie.load('admin_image_uri')}`}
                                alt="profile photo" className="toolbar-account-img-md"/>*/}
                            <img src={`${SCHOOL_LOGO_URL}/${localStorage.getItem('teacherSchoolLogo')}`}
                                 alt="logo" className="toolbar-account-img"/>
                            <div className="font-weight-bold text-white text-capitalize ml-2 font-lora">
                                {localStorage.getItem("teacherSchoolName")}
                            </div>
                        </div>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon nativeColor="#FFF" className='menu-list-icon'/> :
                                <ChevronLeftIcon nativeColor="#FFF" className='menu-list-icon'/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <Menu closeDrawer={this.handleDrawerClose}/>
                </Drawer>
            </div>
        );
    }
}

MenuDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    blacklistToken: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    logoutData: state.blacklistTokenData.data
});

export default connect(mapStateToProps, {blacklistToken})(withStyles(styles, {withTheme: true})(MenuDrawer));