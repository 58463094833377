import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getTerms = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_TERM, {}, 0, true)
    );
    axios.get(urls.TERM_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_TERM, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_TERM, error)
            )
        )
};
