import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getEvent, getEvents} from "../../actions/eventActions";
import connect from "react-redux/src/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {format} from "date-fns";
import {createDate, trimString} from "../../helpers";
import SearchIcon from '@material-ui/icons/Search';
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import {EVENT_IMG_URL} from "../../constants/request_url";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {withRouter} from "react-router-dom";
import history from '../../history';

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Event extends Component {
    constructor(props) {
        super(props);
        this.updateEvent = this.updateEvent.bind(this);
    }

    componentDidMount() {
        document.title = "Event";
        this.props.getEvent(parseInt(this.props.match.params.id, 10));
        this.props.getEvents(0, 5);

        window.onpopstate = () => {
            const {match, location} = this.props;

            if (this.props.history.action === 'POP' && this.props.history.location !== '/') {
                this.props.getEvent(parseInt(match.params.id));
            }
        }
    }

    updateEvent = eventId => e => {
        window.history.pushState({
            id: `event_${eventId}`
        }, 'Event', `/event/${eventId}`);

        /*if (history) {
            this.props.history.push(`/event/${eventId}`);
        }*/

        this.props.getEvent(eventId);
    };

    render() {
        const {classes, data, eventsData, eventsLoading, loading, status} = this.props;
        const {events} = eventsData;
        let displayDate = null;
        let displayTime = null;

        if (data) {
            if (data.date) {
                const date = createDate(data.date);
                displayDate = format(date, "EEE do MMM, yyyy",
                    {awareOfUnicodeTokens: true});
                displayTime = format(date, "h:mm bb",
                    {awareOfUnicodeTokens: true});
            }
        }
        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="event"/>
                <div className="container menu-drawer-h">
                    <div className="row pt-5">
                        <div className="col-12 order-0 order-md-1 col-md-4 mb-4">
                            <Paper className={`${classes.root} mb-4`} elevation={1}>
                                <InputBase className={classes.input} placeholder="Search Events"/>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                            <h4 className="text-primary">RECENT</h4>
                            {
                                eventsLoading &&
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            }
                            <Paper className='w-100'>
                                {
                                    events &&
                                    <div>
                                        {
                                            events.map((event, index) => (
                                                <div key={index} className='recent-event-container'
                                                     onClick={this.updateEvent(event.id)}>
                                                    <div className="p-3 d-flex">
                                                        <img src={`${EVENT_IMG_URL}/${event.imgUri}`}
                                                             alt="events"
                                                             className="recent-event-img"/>
                                                        <div className="ml-3">
                                                            <div>
                                                                <strong className='text-dark'>
                                                                    {trimString(event.title, 25)}
                                                                </strong>
                                                            </div>
                                                            <div className='text-dark'>
                                                                {trimString(event.description, 30)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Divider/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </Paper>
                        </div>
                        <div className="col-12 order-1 order-md-0 col-md-8">
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-5'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                data && !loading &&
                                <Paper className={`${classes.paper} single-event-container`}>
                                    {
                                        data && !loading &&
                                        <Paper className={`${classes.paper} single-event-container`}>
                                            {
                                                status === 200 &&
                                                <div>
                                                    <img src={`${EVENT_IMG_URL}/${data.imgUri}`}
                                                         alt="" className="single-event-img"/>
                                                    <div className="d-flex">
                                                        <h6 className="text-dark p-2">{data.title}</h6>
                                                        {/*<div className="p-2 ml-auto event-date">Wed Oct 24, 2018</div>*/}
                                                        <div className="p-2 ml-auto event-date">
                                                            {displayDate} <span className="ml-3">{displayTime}</span>
                                                        </div>
                                                    </div>
                                                    <div className="p-2">{data.description}</div>
                                                </div>
                                            }
                                            {
                                                status === 404 &&
                                                <div className='p-3'>
                                                    <h5>Invalid event id.</h5>
                                                </div>
                                            }
                                        </Paper>
                                    }
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Event.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getEvent: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.getSingleEventData.data,
    eventsData: state.getEventsData.data,
    eventsLoading: state.getEventsData.loading,
    status: state.getSingleEventData.status,
    loading: state.getSingleEventData.loading
});

export default connect(mapStateToProps, {
    getEvent,
    getEvents
})(withRouter(withStyles(styles, {withTheme: true})(Event)));