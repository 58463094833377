export const logout = () => {
    clearLocalStorage();
    window.location.replace('login');
};

export const clearLocalStorage = () => {
    /*localStorage.removeItem('teacherToken');
    localStorage.removeItem('teacherSchoolLogo');
    localStorage.removeItem('teacherSchoolId');
    localStorage.removeItem('teacherSchoolName');
    localStorage.removeItem('teacherFName');
    localStorage.removeItem('teacherLName');
    localStorage.removeItem('teacherSurname');
    localStorage.removeItem('teacherIdNo');
    localStorage.removeItem('teacherImageUri');
    localStorage.removeItem('teacherLevelName');
    localStorage.removeItem('teacherTermName');*/
    localStorage.clear();
};