import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {InputBase, withStyles} from "@material-ui/core";
import UserPlaceholder from '../../images/placeholders/user.jpg';
import CommentIcon from '@material-ui/icons/InsertCommentOutlined';
import PlusIcon from '@material-ui/icons/Add';
import {
    getPosts, getComments, getReplies, newPost, newComment, newReply, addPost,
    sendPosts, sendComments, sendReplies, addReply, addComment
} from "../../actions/forumActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FROM_PARENT, FROM_TEACHER} from "../../constants/constants";
import {PARENT_IMG_URL, PROFILE_IMG_URL, SOCKET_BASE_URL} from "../../constants/request_url";
import {formatDateTime, formatForumPostDate, trimString, utcDate} from "../../helpers";
import AddPostDialog from "../dialogs/AddPostDialog";
import io from "socket.io-client";
import TimeComponent from "../TimeComponent";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    inputRoot: {
        color: 'inherit',
        background: '#FFFFFF',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    inputInput: {
        width: '100%',
        fontSize: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        }
    },
    inputRootReply: {
        color: 'inherit',
        background: 'rgba(0, 0, 0, 0.12)',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    fab: {
        marginLeft: 10
    }
});

const postsSocket = io(`${SOCKET_BASE_URL}/forum/teacher`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
            }
        }
    },
    forceNew: true,
    reconnection: true
});

const commentSocket = io(`${SOCKET_BASE_URL}/forum/comment/teacher`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
            }
        }
    },
    forceNew: true,
    reconnection: true
});

const replySocket = io(`${SOCKET_BASE_URL}/forum/reply/teacher`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
            }
        }
    },
    forceNew: true,
    reconnection: true
});

class Forum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: '',
            addPostDialogOpen: false,
            comments: {},
            replyText: '',
            commentId: 0
        };

        this.closeDialog = this.closeDialog.bind(this);
        this.handlePostChange = this.handlePostChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.getUnsentPosts = this.getUnsentPosts.bind(this);
        this.onCommentKeyPress = this.onCommentKeyPress.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onSubmitComment = this.onSubmitComment.bind(this);
        this.getUnsentComments = this.getUnsentComments.bind(this);
        this.replyComment = this.replyComment.bind(this);
        this.onReplyChange = this.onReplyChange.bind(this);
        this.onReplyKeyPress = this.onReplyKeyPress.bind(this);
        this.onSubmitReply = this.onSubmitReply.bind(this);

        this.scrollViewRef = React.createRef();
    }


    componentDidMount() {
        document.title = 'Forum';
        this.props.getPosts();
        this.props.getComments();
        this.props.getReplies();
        this.getUnsentPosts();
        this.getUnsentComments();
        this.getUnsentReplies();

        postsSocket.connect();
        const thisRef = this;

        postsSocket.on(`school:${localStorage.getItem('teacherSchoolId')}`, data => {
            if (data.exclude !== localStorage.getItem('teacherIdNo')) {
                thisRef.props.newPost(this.props.postsData.posts, data);
            }
            console.log(data);
        });

        commentSocket.on(`school:${localStorage.getItem('teacherSchoolId')}`, data => {
            if (data.exclude !== localStorage.getItem('teacherIdNo')) {
                thisRef.props.newComment(this.props.commentsData.comments, data);
            }
            console.log(data);
        });

        replySocket.on(`school:${localStorage.getItem('teacherSchoolId')}`, data => {
            if (data.exclude !== localStorage.getItem('teacherIdNo')) {
                thisRef.props.newReply(this.props.repliesData.replies, data);
            }
            console.log(data);
        });
    }

    closeDialog = name => e => {
        this.setState({
            [name]: false
        });
    };

    openDialog = name => event => {
        this.setState({
            [name]: true,
            post: ''
        });
    };

    handlePostChange = event => {
        this.setState({
            post: event.target.value
        });
    };

    handleSubmit = event => {
        const {post} = this.state;
        if (post.trim().length > 0) {
            this.setState({addPostDialogOpen: false});
            this.submit();
        }
    };

    submit = () => {
        const {post} = this.state;
        const date = utcDate();

        const data = {
            id: 0,
            pendingId: date.getTime(),
            category: FROM_TEACHER,
            text: post.trim(),
            datePosted: formatDateTime(date),
            fromIdNo: localStorage.getItem('teacherIdNo'),
            fromName: localStorage.getItem('teacherName'),
            fromImageUri: localStorage.getItem('teacherImageUri'),
        };
        this.props.addPost(this.props.postsData.posts, data);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);
    };

    getUnsentPosts = () => {
        setInterval(() => {
            const {sendingPosts} = this.props;
            const {posts} = this.props.postsData;
            const filtered = posts.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingPosts) {
                const submitData = JSON.stringify({
                    posts: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.datePosted
                    })),
                    fromWeb: true
                });
                this.props.sendPosts(posts, submitData);
            }
        }, 3000);
    };

    getUnsentComments = () => {
        setInterval(() => {
            const {sendingComments} = this.props;
            const {comments} = this.props.commentsData;
            const filtered = comments.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingComments) {
                const submitData = JSON.stringify({
                    comments: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.dateCommented,
                        postId: item.postId
                    })),
                    fromWeb: true
                });
                this.props.sendComments(comments, submitData);
            }
        }, 3000);
    };

    getUnsentReplies = () => {
        setInterval(() => {
            const {sendingReplies} = this.props;
            const {replies} = this.props.repliesData;
            const filtered = replies.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingReplies) {
                // console.log("unsentReplies", filtered);
                const submitData = JSON.stringify({
                    replies: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.dateReplied,
                        commentId: item.commentId
                    })),
                    fromWeb: true
                });
                this.props.sendReplies(replies, submitData);
            }
        }, 3000);
    };

    onCommentKeyPress = postId => event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        if (code === 13) {
            event.preventDefault();
            const {comments} = this.state;
            const mComments = {...comments};
            const text = mComments[postId.toString()];
            if (text && text.trim() !== '') {
                const date = utcDate();

                const data = {
                    id: 0,
                    pendingId: date.getTime(),
                    category: FROM_TEACHER,
                    text: text.trim(),
                    dateCommented: formatDateTime(date),
                    fromIdNo: localStorage.getItem('teacherIdNo'),
                    fromName: localStorage.getItem('teacherName'),
                    fromImageUri: localStorage.getItem('teacherImageUri'),
                    postId: postId
                };
                this.props.addComment(this.props.commentsData.comments, data);
                mComments[postId.toString()] = '';
                this.setState({comments: mComments});
            }
        }
    };

    onSubmitComment = postId => event => {
        const {comments} = this.state;
        const mComments = {...comments};
        const text = mComments[postId.toString()];
        if (text && text !== '') {
            const date = utcDate();
            const data = {
                id: 0,
                pendingId: date.getTime(),
                category: FROM_TEACHER,
                text: text.trim(),
                dateCommented: formatDateTime(date),
                fromIdNo: localStorage.getItem('teacherIdNo'),
                fromName: localStorage.getItem('teacherName'),
                fromImageUri: localStorage.getItem('teacherImageUri'),
                postId: postId
            };
            this.props.addComment(this.props.commentsData.comments, data);
            mComments[postId.toString()] = '';
            this.setState({comments: mComments});
        }
    };

    onCommentChange = postId => event => {
        const {comments} = this.state;
        const mComments = {...comments};
        mComments[postId.toString()] = event.target.value;
        this.setState({comments: mComments})
    };

    replyComment = commentId => event => {
        this.setState({commentId: commentId, replyText: ''})
    };

    onReplyChange = event => {
        this.setState({replyText: event.target.value});
    };

    onReplyKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        if (code === 13) {
            event.preventDefault();
            const {commentId, replyText} = this.state;
            if (replyText.trim() !== '') {
                const date = utcDate();

                const data = {
                    id: 0,
                    pendingId: date.getTime(),
                    category: FROM_TEACHER,
                    text: replyText.trim(),
                    dateReplied: formatDateTime(date),
                    fromIdNo: localStorage.getItem('teacherIdNo'),
                    fromName: localStorage.getItem('teacherName'),
                    fromImageUri: localStorage.getItem('teacherImageUri'),
                    commentId: commentId
                };
                this.props.addReply(this.props.repliesData.replies, data);
                this.setState({commentId: 0, reply: ''});
            }
        }
    };

    onSubmitReply = event => {
        const {commentId, replyText} = this.state;
        if (replyText.trim() !== '') {
            const date = utcDate();

            const data = {
                id: 0,
                pendingId: date.getTime(),
                category: FROM_TEACHER,
                text: replyText.trim(),
                dateReplied: formatDateTime(date),
                fromIdNo: localStorage.getItem('teacherIdNo'),
                fromName: localStorage.getItem('teacherName'),
                fromImageUri: localStorage.getItem('teacherImageUri'),
                commentId: commentId
            };
            this.props.addReply(this.props.repliesData.replies, data);
            this.setState({commentId: 0, reply: ''});
        }
    };

    render() {
        const {postsData, commentsData, repliesData, postsLoading, commentsLoading, repliesLoading,
            classes} = this.props;
        const {addPostDialogOpen, post} = this.state;
        const {posts} = postsData;
        const {comments} = commentsData;
        const {replies} = repliesData;

        const data = [];

        if (!postsLoading && !commentsLoading && !repliesLoading) {
            posts.forEach(item => {
                const postComments = comments.filter(x => x.postId === item.id).map(ele => {
                    return ({...ele, replies: replies.filter(x => x.commentId === ele.id)})
                });
                data.push({...item, comments: postComments});
            });
        }
        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="forum"/>
                <div className='forum-main-container' ref={this.scrollViewRef}>
                    {
                        postsLoading || commentsLoading || repliesLoading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }

                    {/*{
                            leaves && leaves.length === 0 &&
                            <Paper>
                                <div className='p-3'>
                                    No leave has been issued
                                </div>
                            </Paper>
                        }*/}
                    {
                        !postsLoading && !commentsLoading && !repliesLoading &&
                        <div className='btn-new-post'>
                            <div className='text-white' onClick={this.openDialog('addPostDialogOpen')}><PlusIcon className='menu-list-icon'/> <span>Add New Forum Post</span></div>
                        </div>
                    }
                    {
                        !postsLoading && !commentsLoading && !repliesLoading &&
                        <div className='forum-container'>
                            {
                                data.map((item, index) => (
                                    <div className='forum-item mt-4' key={index}>
                                        <div className='d-flex align-items-center'>
                                            {
                                                !item.fromImageUri &&
                                                <img src={UserPlaceholder} alt="" className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_PARENT &&
                                                <img src={`${PARENT_IMG_URL}/${item.fromImageUri}`} alt="" className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_TEACHER &&
                                                <img src={`${PROFILE_IMG_URL}/${item.fromImageUri}`} alt="" className='forum-profile-img'/>
                                            }
                                            <div className='ml-2'>
                                                <div className='text-capitalize'>{item.fromName} <span className='text-black-50'>[{item.category === FROM_TEACHER ? 'Teacher' : 'Parent'}]</span></div>
                                                <div className='text-black-50'><TimeComponent time={item.datePosted} formatFunction={formatForumPostDate}/></div>
                                            </div>
                                        </div>
                                        <div>{item.text}</div>
                                        {
                                            item.comments.length > 0 &&
                                            <div className='text-black-50 mt-1'>{item.comments.length} comment{item.comments.length > 0 ? 's' : ''}</div>
                                        }

                                        {
                                            item.comments.length > 0 &&
                                            <div className='mt-1'>
                                                {
                                                    item.comments.map((comment, commentIndex) => (
                                                        <div className="comment-item d-flex justify-content-start" key={commentIndex}>
                                                            {
                                                                !comment.fromImageUri &&
                                                                <img src={UserPlaceholder} alt="" className='comment-profile-img'/>
                                                            }
                                                            {
                                                                comment.fromImageUri && comment.category === FROM_PARENT &&
                                                                <img src={`${PARENT_IMG_URL}/${comment.fromImageUri}`} alt="" className='comment-profile-img'/>
                                                            }
                                                            {
                                                                comment.fromImageUri && comment.category === FROM_TEACHER &&
                                                                <img src={`${PROFILE_IMG_URL}/${comment.fromImageUri}`} alt="" className='comment-profile-img'/>
                                                            }
                                                            <div className='pl-2'>
                                                                <div className='text-capitalize'><strong>{comment.fromName}</strong> <span
                                                                    className='text-black-50'>[{comment.category === FROM_TEACHER ? 'Teacher' : 'Parent'}]</span></div>
                                                                <div>{comment.text}</div>
                                                                <div className='text-black-50'>
                                                                    <span><TimeComponent time={comment.dateCommented} formatFunction={formatForumPostDate}/></span>
                                                                    {
                                                                        comment.id !== 0 &&
                                                                        <span
                                                                            className='ml-3 cursor-pointer' onClick={this.replyComment(comment.id)}>Reply</span>
                                                                    }
                                                                </div>

                                                                {
                                                                    this.state.commentId === comment.id && comment.id > 0 &&
                                                                    <div
                                                                        className='d-flex align-items-end mt-2'>
                                                                        <InputBase fullWidth
                                                                                   placeholder='Write a reply...'
                                                                                   multiline
                                                                                   rows={1}
                                                                                   rowsMax={5}
                                                                                   classes={{
                                                                                       root: classes.inputRootReply,
                                                                                       input: classes.inputInput,
                                                                                   }}
                                                                                   value={this.state.replyText}
                                                                                   onChange={this.onReplyChange}
                                                                                   onKeyPress={this.onReplyKeyPress}/>
                                                                        <Fab color="primary"
                                                                             aria-label="Add"
                                                                             className={`${classes.fab} text-white`}
                                                                             size='small'
                                                                             onClick={this.onSubmitReply}
                                                                             disabled={this.state.replyText.trim() === ''}>
                                                                            <SendIcon/>
                                                                        </Fab>
                                                                    </div>
                                                                }

                                                                {
                                                                    comment.replies.length > 0 &&
                                                                    <div>
                                                                        {
                                                                            comment.replies.map((reply, replyIndex) => (
                                                                                <div className='pl-3 pt-1 pb-1 ' key={replyIndex}>
                                                                                    <div className="d-flex" key={replyIndex}>
                                                                                        {
                                                                                            !reply.fromImageUri &&
                                                                                            <img src={UserPlaceholder} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_PARENT &&
                                                                                            <img src={`${PARENT_IMG_URL}/${reply.fromImageUri}`} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_TEACHER &&
                                                                                            <img src={`${PROFILE_IMG_URL}/${reply.fromImageUri}`} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        <div className='ml-2'>
                                                                                            <div className='text-capitalize'><strong>{trimString(reply.fromName, 20)}</strong></div>
                                                                                            <div className='text-black-50'>{trimString(reply.text, 20)}</div>
                                                                                            <div className='text-black-50 last-seen-text'>
                                                                                                <span><TimeComponent time={reply.dateReplied} formatFunction={formatForumPostDate}/></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }

                                        {
                                            item.id !== 0 &&
                                            <div className='d-flex align-items-end mt-2'>
                                                <InputBase fullWidth placeholder='Write a comment...'
                                                           multiline
                                                           rows={1}
                                                           rowsMax={5}
                                                           classes={{
                                                               root: classes.inputRoot,
                                                               input: classes.inputInput,
                                                           }}
                                                           value={this.state.comments[item.id.toString()] ? this.state.comments[item.id.toString()]: ''}
                                                           onChange={this.onCommentChange(item.id)}
                                                           onKeyPress={this.onCommentKeyPress(item.id)}/>
                                                <Fab color="primary" aria-label="Add" className={`${classes.fab} text-white`} size='small'
                                                     onClick={this.onSubmitComment(item.id)}
                                                     disabled={!this.state.comments[item.id.toString()] || this.state.comments[item.id.toString()].trim() === ''}>
                                                    <SendIcon/>
                                                </Fab>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    }

                </div>

                <AddPostDialog
                    open={addPostDialogOpen}
                    post={post}
                    handleChange={this.handlePostChange}
                    handleClose={this.closeDialog('addPostDialogOpen')}
                    handleSubmit={this.handleSubmit}/>
            </MuiThemeProvider>
        );
    }
}

Forum.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getPosts: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    getReplies: PropTypes.func.isRequired,
    newPost: PropTypes.func.isRequired,
    newComment: PropTypes.func.isRequired,
    newReply: PropTypes.func.isRequired,
    addPost: PropTypes.func.isRequired,
    sendPosts: PropTypes.func.isRequired,
    sendComments: PropTypes.func.isRequired,
    sendReplies: PropTypes.func.isRequired,
    addComment: PropTypes.func.isRequired,
    addReply: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    postsData: state.postsData.data,
    postsLoading: state.postsData.loading,
    commentsData: state.commentsData.data,
    commentsLoading: state.commentsData.loading,
    repliesData: state.repliesData.data,
    repliesLoading: state.repliesData.loading,
    sendingPosts: state.sendPostsData.loading,
    sendingComments: state.sendCommentsData.loading,
    sendingReplies: state.sendRepliesData.loading,
});

export default connect(
    mapStateToProps,
    {
        getPosts,
        getComments,
        getReplies,
        newPost,
        newComment,
        newReply,
        addPost,
        sendPosts,
        sendComments,
        sendReplies,
        addComment,
        addReply
    })
(withStyles(styles, {withTheme: true})(Forum));