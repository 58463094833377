import axios from '../axios/axios';
import {TEACHER_URL} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import * as actionTypes from '../constants/actionTypes';
import {loading} from "./helperActions";
import {axiosConfig} from "../constants/constants";

export const sendDeviceToken = (data) => dispatch => {
    dispatch(
        loading(actionTypes.SEND_DEVICE_TOKEN, {}, 0, true)
    );
    axios.post(`${TEACHER_URL}fcm/web/`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.SEND_DEVICE_TOKEN, response.data, response.status)
            );
            setTimeout(function () {
                dispatch(
                    resetSuccess(response.data, actionTypes.SEND_DEVICE_TOKEN)
                )
            }, 4000);
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.SEND_DEVICE_TOKEN, error)
                );
                setTimeout(function () {
                    dispatch(
                        resetError(error, actionTypes.SEND_DEVICE_TOKEN)
                    )
                }, 4000);
            }
        )
};