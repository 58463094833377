import axios from '../axios/axios';
import {EVENT_URL} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import * as actionTypes from '../constants/actionTypes';
import {loading} from "./helperActions";

export const getEvents = (offset, limit) => dispatch => {
    dispatch(
        loading(actionTypes.GET_EVENTS, {}, 0, true)
    );
    const schoolId = localStorage.getItem('teacherSchoolId');

    axios.get(`${EVENT_URL}teacher/${offset}/${limit}/${schoolId}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_EVENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_EVENTS, error)
                );
            }
        )
};

export const getEvent = id => dispatch => {
    dispatch(
        loading(actionTypes.GET_EVENT, {}, 0, true)
    );
    if (typeof id !== 'number') {
        dispatch(
            loading(actionTypes.GET_EVENT, {}, 404, false)
        );
    } else {
        axios.get(`${EVENT_URL}teacher/single/${id}`)
            .then(response => {
                dispatch(
                    handleSuccessResponse(actionTypes.GET_EVENT, response.data, response.status)
                );
            })
            .catch(error => {
                    dispatch(
                        handleErrorResponse(actionTypes.GET_EVENT, error)
                    );
                }
            )
    }
};

export const getAllEvents = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_ALL_EVENTS, {}, 0, true)
    );

    axios.get(`${EVENT_URL}teacher`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_ALL_EVENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_ALL_EVENTS, error)
                );
            }
        )
};