export const axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
    }
};

export const allowedImageExtensions = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];
export const allowedExcelExtensions = ['xlsx', 'XLSX'];
export const maxYear = 2030;
export const minYear = 2018;
export const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyB4isSxSS0yE_3AcAJjm-ahoNWclAG2Xa0",
    authDomain: "do-it-online.firebaseapp.com",
    databaseURL: "https://do-it-online.firebaseio.com",
    projectId: "do-it-online",
    storageBucket: "do-it-online.appspot.com",
    messagingSenderId: "834628437933",
    appId: "1:834628437933:web:350480da03acb904d1c324"
};

export  const PARENT_TO_TEACHER = 1;
export const TEACHER_TO_PARENT = 2;
export  const FROM_PARENT = 1;
export const FROM_TEACHER = 2;

export const axiosFileConfig = {
    headers: {
        'Content-Disposition': "attachment; filename=template.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        "Access-Control-Allow-Origin": "*"
    },
    responseType: 'arraybuffer'
};