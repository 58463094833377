import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, authError, ...rest}) => (
    <Route
        {...rest}
        render={props => (
            authError
                ? <Redirect to="/login" push/>
                : <Component {...props} />
        )}
    />
);

export default PrivateRoute;