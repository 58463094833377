import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {showError} from "../../actions/helperActions";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {titleCase} from "../../helpers";
import {format, parse} from "date-fns";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getHomeworkQuestions} from "../../actions/homeworkActions";
import {withRouter} from "react-router-dom";
import {HOMEWORK_IMG_URL} from "../../constants/request_url";

const styles = theme => ({});

class HomeworkQuestions extends Component {

    componentDidMount() {
        document.title = 'Homework Questions';
        this.props.getHomeworkQuestions(parseInt(this.props.match.params.id, 10));
    }

    render() {
        const {data, loading} = this.props;
        let displayDate;
        let displaySubmissionDate;

        if (data.data && data.data.length > 0) {
            const date = parse(data.date, "yyyy-MM-dd", new Date());
            displayDate = format(date, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});
            const submissionDate = parse(data.submissionDate, "yyyy-MM-dd", new Date());
            displaySubmissionDate = format(submissionDate, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});
        }

        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="Homework Questions"/>
                <div className="menu-drawer-h p-3 container d-flex justify-content-center">
                    <div className='w-75'>

                        <div className='mt-0'>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-1'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                data.data && data.data.length > 0 && !loading &&
                                <div>
                                    <div className='mb-4'>
                                        <div className='mt-2'>
                                            <strong>Subject</strong>: <span>{titleCase(data.subject)}</span>
                                        </div>
                                        <div className='mt-2'>
                                            <strong>{titleCase(localStorage.getItem("teacherLevelName"))}</strong>: <span>{titleCase(data.level)}</span>
                                        </div>
                                        <div className='mt-2'>
                                            <strong>Stream</strong>: <span>{titleCase(data.stream)}</span>
                                        </div>
                                        <div className='mt-2'>
                                            <strong>Date</strong>: <span>{displayDate}</span>
                                        </div>
                                        <div className='mt-2'>
                                            <strong>Submission Date: </strong>
                                            <span>{displaySubmissionDate}</span>
                                        </div>
                                    </div>
                                    {
                                        data.data.map((item, index) => {
                                            return (
                                                <Paper key={index} className='mb-4 p-3' elevation={1}>
                                                    <div className='mt-2'>
                                                        <strong>Question {index + 1}</strong>
                                                    </div>
                                                    <p className='mt-2'>
                                                        {item.question}
                                                    </p>
                                                    {
                                                        item.imageUri &&
                                                        <div className='mt-2'>
                                                            <img
                                                                src={`${HOMEWORK_IMG_URL}/${item.imageUri}`}
                                                                alt="homework image" className="homework-img w-100 h-auto"/>
                                                        </div>
                                                    }
                                                </Paper>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                data.data && data.data.length === 0 && !loading &&
                                <Paper>
                                    <div className='p-3'>
                                        Homework does not exist.
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

HomeworkQuestions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getHomeworkQuestions: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.homeworkQuestionsData.data,
    status: state.homeworkQuestionsData.status,
    loading: state.homeworkQuestionsData.loading,
});

export default connect(mapStateToProps, {
    getHomeworkQuestions
})(withRouter(withStyles(styles, {withTheme: true})(HomeworkQuestions)));