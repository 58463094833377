import * as actionTypes from "./constants/actionTypes";
import {parse, startOfDay, addDays, differenceInHours, format, differenceInMinutes} from 'date-fns';
import {toast} from 'react-toastify';

export const resetSuccess = (data, actionType) => {
    let myData = {...data};
    myData.error = null;
    return {
        type: actionType,
        data: myData,
        status: 200,
        loading: false
    }
};

export const resetError = (error, actionType) => {
    try {
        const data = {...error.response.data};
        data.error = null;
        return {
            type: actionType,
            data: data,
            status: error.response.status,
            loading: false,
            network_error: false
        }
    } catch (e) {
        return {
            type: actionType,
            data: {},
            status: 0,
            loading: false,
            network_error: true
        }
    }
};

export const handleSuccessResponse = (actionType, data, status, showSuccess = false) => {
    if (showSuccess) {
        toastSuccess(data.message);
    }
    return {
        type: actionType,
        data: data,
        status: status,
        loading: false
    }
};

export const handleErrorResponse = (actionType, error, showError = false) => {
    try {
        if (error.response.status === 401) {
            return {
                type: actionTypes.AUTH_ERROR,
                data: error.response.data,
                status: error.response.status,
                loading: false,
                network_error: false
            }
        } else {
            if (showError) {
                toastError(error.response.data.message);
            }
            return {
                type: actionType,
                data: error.response.data,
                status: error.response.status,
                loading: false,
                network_error: false
            }
        }
    } catch (e) {
        toastError("Please check your internet connection");
        return {
            type: actionType,
            data: {},
            status: 0,
            loading: false,
            network_error: true
        }
    }
};

export const handleAuthErrorResponse = (actionType, error) => {
    try {
            return {
                type: actionType,
                data: error.response.data,
                status: error.response.status,
                loading: false,
                network_error: false
            }
    } catch (e) {
        return {
            type: actionType,
            data: {},
            status: 0,
            loading: false,
            network_error: true
        }
    }
};

export const trimString = (str, length) => {
    if (str.length > length) {
        return `${str.substring(0, length)}...`
    } else {
        return str.substring(0, length)
    }
};

export const titleCase = str => {
    return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

export const verifyExtension = (fileName, allowedExtensionArray) => {
    let verified = false;
    const extension = fileName.substr((fileName.lastIndexOf('.') + 1));
    allowedExtensionArray.forEach(function (ext) {
        if (extension === ext) {
            verified = true;
        }
    });

    return verified;
};

export const createDate = dateStr => {
    const _dateStrArr = dateStr.split(' ')[0].split('-');
    const timeStrArr = dateStr.split(' ')[1].split(':');
    const dateArr = [];
    const timeArr = [];
    _dateStrArr.forEach(item => {
        dateArr.push(parseInt(item, 10))
    });
    timeStrArr.forEach(item => {
        timeArr.push(parseInt(item, 10))
    });
    const utcDate = Date.UTC(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1]);
    /*let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const dateMilli = utcDate + offset;*/
    return new Date(utcDate);
};

export const formatLatestChatDate = dateStr => {
    const today = new Date();
    let date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() + offset;
    date = new Date(millis);
    const startOfTheDayToday = startOfDay(today);
    const startOfTheDayTomorrow = addDays(startOfTheDayToday, 1);
    const diffHr = differenceInHours(date, startOfTheDayToday);
    const diffHrTomorrow = differenceInHours(startOfTheDayTomorrow, date);
    if (diffHr >= 0 && diffHr <= 23) {
        return format(date, "H:mm",{awareOfUnicodeTokens: true});
    } else if (diffHrTomorrow >= 24 && diffHrTomorrow <= 47) {
        return "Yesterday";
    }

    return format(date, "dd/MM/yyyy",{awareOfUnicodeTokens: true});
};

export const formatTime = dateStr => {
    const today = new Date();
    let date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() + offset;
    date = new Date(millis);

    return format(date, "h:mm a",{awareOfUnicodeTokens: true});
};

export const formatChatGroupDate = dateStr => {
    const today = new Date();
    let date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() + offset;
    date = new Date(millis);
    const startOfTheDayToday = startOfDay(today);
    const startOfTheDayTomorrow = addDays(startOfTheDayToday, 1);
    const diffHr = differenceInHours(date, startOfTheDayToday);
    const diffHrTomorrow = differenceInHours(startOfTheDayTomorrow, date);
    if (diffHr >= 0 && diffHr <= 23) {
        return 'Today';
    } else if (diffHrTomorrow >= 24 && diffHrTomorrow <= 47) {
        return "Yesterday";
    }

    return format(date, "dd/MM/yyyy",{awareOfUnicodeTokens: true});
};

export const formatStatusDate = dateStr => {
    const today = new Date();
    let date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() + offset;
    date = new Date(millis);
    const startOfTheDayToday = startOfDay(today);
    const startOfTheDayTomorrow = addDays(startOfTheDayToday, 1);
    const diffHr = differenceInHours(date, startOfTheDayToday);
    const diffHrTomorrow = differenceInHours(startOfTheDayTomorrow, date);
    if (diffHr >= 0 && diffHr <= 23) {
        return format(date, "HH:mm",{awareOfUnicodeTokens: true});
    } else if (diffHrTomorrow >= 24 && diffHrTomorrow <= 47) {
        return `Yesterday, ${format(date, "HH:mm",{awareOfUnicodeTokens: true})}`;
    }

    return format(date, "HH:mm, dd/MM/yyyy",{awareOfUnicodeTokens: true});
};

export const utcDate = () => {
    let date = new Date();
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() - offset;
    return  new Date(millis);
};

export const formatDateTime = date => {
    return format(date, "yyyy-MM-dd HH:mm:ss",{awareOfUnicodeTokens: true});
};

export const getDateMillis = dateStr => {
    const today = new Date();
    const date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    return date.getTime();
};

export const formatForumPostDate = dateStr => {
    const today = new Date();
    let date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", today);
    let offset = new Date().getTimezoneOffset() * -1;
    offset = offset * 1000 * 60;
    const millis = date.getTime() + offset;
    date = new Date(millis);
    const startOfTheDayToday = startOfDay(today);
    const startOfTheDayTomorrow = addDays(startOfTheDayToday, 1);
    const diffHr = differenceInHours(date, startOfTheDayToday);
    const diffHrTomorrow = differenceInHours(startOfTheDayTomorrow, date);
    if (diffHr >= 0 && diffHr <= 23) {
        const diffMin = differenceInMinutes(today, date);
        const diffHour = differenceInHours(today, date);
        if (diffHour >= 1) {
            return `${diffHour} hr ago`
        } else if (diffMin > 1) {
            return `${diffMin} min ago`
        } else {
            return 'just now'
        }
    } else if (diffHrTomorrow >= 24 && diffHrTomorrow <= 47) {
        return `Yesterday at ${format(date, "HH:mm",{awareOfUnicodeTokens: true})}`;
    }

    return format(date, "dd/MM/yyyy",{awareOfUnicodeTokens: true});
};

export const toastError = message => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
    });
};

export const toastSuccess = message => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
    });
};

export const toastWarning = message => {
    toast.warn(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false
    });
};