import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide/Slide";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// ...or load this specific CSS file using a <link> tag in your document
// import 'react-fine-uploader/gallery/gallery.css';
import Button from "@material-ui/core/Button";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import CropIcon from '@material-ui/icons/Crop';
import {showError} from "../../actions/helperActions";
import {WhiteCircularProgress} from "../progress_bars/ProgressBars";
import {addHomework} from "../../actions/homeworkActions";
import {getClassLevels} from "../../actions/classLevelActions";
import {getTerms} from "../../actions/termsActions";
import {getTeachingSubjects} from "../../actions/subjectActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {titleCase, toastError} from "../../helpers";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getStreams} from "../../actions/streamActions";
import {DatePicker} from "material-ui-pickers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {format} from "date-fns"
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const imageStyle = {
    display: 'block',
    width: '50%',
    height: 'auto'
};

class AddHomeworkDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pictures: [],
            file: '',
            imagePreviewUrl: '',
            title: '',
            description: '',
            pixelCrop: null,
            fileName: null,
            croppedImage: null,
            croppedImgUrl: null,
            cropComplete: false,
            questions: [
                {
                    question: "",
                    file: '',
                    imagePreviewUrl: '',
                    cropComplete: false,
                    croppedImgUrl: null,
                    croppedImage: null
                },
                {
                    question: "",
                    file: '',
                    imagePreviewUrl: '',
                    cropComplete: false,
                    croppedImgUrl: null,
                    croppedImage: null
                },
                {
                    question: "",
                    file: '',
                    imagePreviewUrl: '',
                    cropComplete: false,
                    croppedImgUrl: null,
                    croppedImage: null
                },
                {
                    question: "",
                    file: '',
                    imagePreviewUrl: '',
                    cropComplete: false,
                    croppedImgUrl: null,
                    croppedImage: null
                },
                {
                    question: "",
                    file: '',
                    imagePreviewUrl: '',
                    cropComplete: false,
                    croppedImgUrl: null,
                    croppedImage: null
                }
            ],
            subject: '',
            date: new Date(),
            classLevel: '',
            stream: ''
        };

        for (let i = 0; i <= 100; i++) {
            this[`cropperRef${i}`] = React.createRef();
        }

        this.cropperRefs = [];

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this._crop = this._crop.bind(this);
        this.completeCrop = this.completeCrop.bind(this);
        this.resetCrop = this.resetCrop.bind(this);
        this.crop = this.crop.bind(this);
        this.setRef = this.setRef.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
    }

    componentDidMount() {
        getClassLevels();
        getTerms();
    }

    onChange = (crop, pixelCrop) => {
        this.setState({crop, pixelCrop});
    };

    handleAddRow = () => {
        const item = {
            question: "",
            file: '',
            imagePreviewUrl: '',
            cropComplete: false,
            croppedImgUrl: null,
            croppedImage: null
        };
        this.setState({
            questions: [...this.state.questions, item]
        });
    };
    handleRemoveRow = () => {
        this.setState({
            questions: this.state.questions.slice(0, -1)
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const {questions, classLevel, date, stream, subject} = this.state;

        if (classLevel !== '' && stream !== '' && subject !== '') {

            let empty = false;
            let cropComplete = true;
            const data = new FormData();
            const myQuestions = [];
            questions.forEach((item, index) => {
                myQuestions.push({
                    'question': item.question
                });

                if (item.question === '') {
                    empty = true;
                }

                data.append("file[]", item.croppedImage);
                if (item.croppedImage != null) {
                    data.append(`image${index}`, item.croppedImage);
                }

                if (item.croppedImage != null && !item.cropComplete) {
                    cropComplete = false;
                }

            });
            if (!empty && cropComplete) {
                data.append('data', JSON.stringify({
                    'items': myQuestions
                }));
                const serverDate = format(date, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                this.props.addHomework(classLevel, stream, subject, serverDate, data);
            } else if (empty) {
                toastError("Please fill all fields");
            } else {
                toastError("Please finish cropping the image");
            }
        } else {
            toastError("Please fill all fields");
        }
    };

    handleDateChange = (date) => {
        this.setState({date})
    };

    handleChange = index => e => {
        const questions = [...this.state.questions];
        questions[index]['question'] = e.target.value;
        this.setState({questions: questions});
    };

    handleImageChange = index => e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        const questions = [...this.state.questions];
        console.log(index);

        reader.onloadend = () => {
            questions[index]['file'] = file;
            questions[index]['cropComplete'] = false;
            questions[index]['imagePreviewUrl'] = reader.result;
            this.setState({questions: questions});
        };

        reader.readAsDataURL(file)
    };

    setRef = ref => {
        this.cropperRefs.push(ref);
    };

    _crop = index => () => {
        // const file = dataURLtoFile(this.cropperRef.current.getCroppedCanvas().toDataURL(), this.state.file.name);
        const questions = [...this.state.questions];

        this[`cropperRef${index}`].current.getCroppedCanvas().toBlob(blob => {
            const imgFile = new File([blob], questions[index].file.name);
            const url = URL.createObjectURL(imgFile);
            questions[index]['croppedImage'] = imgFile;
            questions[index]['croppedImgUrl'] = url;
            this.setState({questions: questions})
        }, 'image/jpeg', 1);
    };

    completeCrop = index => () => {
        const questions = [...this.state.questions];
        questions[index]['cropComplete'] = true;
        this.setState({questions: questions})
    };

    resetCrop = index => () => {
        this[`cropperRef${index}`].current.reset();
    };

    crop = index => () => {
        const questions = [...this.state.questions];
        questions[index]['cropComplete'] = false;
        this.setState({questions: questions})
    };


    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    render() {
        const {classes, loading} = this.props;
        const {questions, classLevel, date, stream, subject} = this.state;
        const {levels} = this.props.levelsData;
        const {streams} = this.props.streamsData;
        const {subjects} = this.props.subjectsData;

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <div className="container mb-3">
                        <div className="row clearfix">
                            <div className="col-md-12 column">
                                <form onSubmit={this.handleSubmit}>
                                    <AppBar className={classes.appBar}>
                                        <Toolbar>
                                            <IconButton color="inherit" onClick={this.props.handleClose}
                                                        aria-label="Close">
                                                <CloseIcon/>
                                            </IconButton>
                                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                                Add Homework
                                            </Typography>
                                            <Button color="inherit" type="submit">
                                                {
                                                    !loading &&
                                                    <span>Add</span>
                                                }
                                                {
                                                    loading &&
                                                    <WhiteCircularProgress size={30} thickness={5}/>
                                                }
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                    <div className="container"
                                         style={{marginTop: 90}}>

                                        <div className='d-flex flex-sm-column flex-md-row pl-3'>
                                            {
                                                !levels &&
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel shrink htmlFor="level-label-placeholder">
                                                        {titleCase(localStorage.getItem("teacherLevelName"))}
                                                    </InputLabel>
                                                    <Select
                                                        value={classLevel}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="classLevel"
                                                                      id="level-label-placeholder"/>}
                                                        displayEmpty
                                                        name="classLevel"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>
                                                            <CircularProgress color='primary' size={30}
                                                                              thickness={5}/>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                            {
                                                levels &&
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel shrink htmlFor="level-label-placeholder">
                                                        {titleCase(localStorage.getItem("teacherLevelName"))}
                                                    </InputLabel>
                                                    <Select
                                                        value={classLevel}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="classLevel"
                                                                      id="level-label-placeholder"/>}
                                                        displayEmpty
                                                        name="classLevel"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem
                                                            value=''>{titleCase(localStorage.getItem("teacherLevelName"))}...</MenuItem>
                                                        {
                                                            levels.map((item, index) => (
                                                                <MenuItem value={item.id} key={index}>
                                                                    {titleCase(item.name)}
                                                                </MenuItem>
                                                            ))
                                                        }

                                                    </Select>
                                                </FormControl>
                                            }

                                            {
                                                !streams &&
                                                <FormControl className='ml-3' fullWidth>
                                                    <InputLabel shrink htmlFor="stream-label-placeholder">
                                                        Stream
                                                    </InputLabel>
                                                    <Select
                                                        value={stream}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="stream"
                                                                      id="stream-label-placeholder"/>}
                                                        displayEmpty
                                                        name="stream"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>
                                                            <CircularProgress color='primary' size={30}
                                                                              thickness={5}/>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                            {
                                                streams &&
                                                <FormControl className='ml-3'>
                                                    <InputLabel shrink htmlFor="stream-label-placeholder">
                                                        Stream
                                                    </InputLabel>
                                                    <Select
                                                        value={stream}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="stream"
                                                                      id="stream-label-placeholder"/>}
                                                        displayEmpty
                                                        name="stream"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>Choose stream...</MenuItem>
                                                        {
                                                            streams.map((stream, index) => (
                                                                <MenuItem value={stream.value} key={index}>
                                                                    {titleCase(stream.name)}
                                                                </MenuItem>
                                                            ))
                                                        }

                                                    </Select>
                                                </FormControl>
                                            }

                                            {
                                                !subjects &&
                                                <FormControl className='ml-3'>
                                                    <InputLabel shrink htmlFor="subject-label-placeholder">
                                                        Subject
                                                    </InputLabel>
                                                    <Select
                                                        value={subject}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="subject"
                                                                      id="subject-label-placeholder"/>}
                                                        displayEmpty
                                                        name="subject"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>
                                                            <CircularProgress color='primary' size={30}
                                                                              thickness={5}/>
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                            {
                                                subjects &&
                                                <FormControl className='ml-3'>
                                                    <InputLabel shrink htmlFor="subject-label-placeholder">
                                                        Subject
                                                    </InputLabel>
                                                    <Select
                                                        value={subject}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="subject"
                                                                      id="subject-label-placeholder"/>}
                                                        displayEmpty
                                                        name="subject"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>Choose subject...</MenuItem>
                                                        {
                                                            subjects
                                                                .map((subject, index) => (
                                                                    <MenuItem value={subject.id} key={index}>
                                                                        {titleCase(subject.name)}
                                                                    </MenuItem>
                                                                ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            }

                                            <DatePicker
                                                value={date}
                                                onChange={this.handleDateChange}
                                                leftArrowIcon={<ChevronLeftIcon/>}
                                                rightArrowIcon={<ChevronRightIcon/>}
                                                label="Submission Date"
                                                className="ml-3"
                                                format="dd MMM yyyy"
                                                disablePast
                                                openToYearSelection={false}
                                            />
                                        </div>
                                        <div>
                                            <div className="w-75 pb-5 mt-4">
                                                {
                                                    questions.map((item, index) => {
                                                        let $croppedImgPreview = null;
                                                        if (item.croppedImgUrl) {
                                                            $croppedImgPreview = (
                                                                <img src={item.croppedImgUrl} style={imageStyle}/>);
                                                        }

                                                        return (
                                                            <div key={index}
                                                                 className={index === 0 ? 'question-item p-3 pt-0' : 'p-3 question-item'}>
                                                                {
                                                                    questions.length > 1 &&
                                                                    <div
                                                                        className='position-absolute d-flex justify-content-end w-75'>
                                                                        <DeleteIcon className="icon"
                                                                                    onClick={event => {
                                                                                        let myQuestions = [...this.state.questions];
                                                                                        myQuestions.splice(index, 1);
                                                                                        this.setState({
                                                                                            questions: myQuestions
                                                                                        });
                                                                                    }}/>
                                                                    </div>
                                                                }
                                                                <div className="form-group">
                                                                    <label htmlFor={`label-${index}`}>
                                                                        Question {index + 1}
                                                                    </label>
                                                                    <textarea className="form-control"
                                                                              id={`label-${index}`}
                                                                              rows="3"
                                                                              onChange={this.handleChange(index)}/>
                                                                </div>

                                                                <div className="mt-1 mb-2">Image[Diagram](Optional)
                                                                </div>
                                                                <input
                                                                    accept="image/*"
                                                                    style={{display: 'none'}}
                                                                    id={`raised-button-file-${index}`}
                                                                    type="file"
                                                                    onChange={this.handleImageChange(index)}
                                                                />
                                                                <label htmlFor={`raised-button-file-${index}`}>
                                                                    <Button variant="contained" component="span">
                                                                        Choose Image
                                                                    </Button>
                                                                </label>
                                                                {/*{$imagePreview}*/}
                                                                {
                                                                    item.imagePreviewUrl && !item.cropComplete &&
                                                                    <div>
                                                                        <Cropper
                                                                            ref={this[`cropperRef${index}`]}
                                                                            src={item.imagePreviewUrl}
                                                                            style={{height: 400, width: '100%'}}
                                                                            aspectRatio={16 / 9}
                                                                            guides={false}
                                                                            crop={this._crop(index)}
                                                                        />
                                                                        <div
                                                                            className='mt-3 d-flex justify-content-between'>
                                                                            <Button variant="contained"
                                                                                    component="span"
                                                                                    onClick={this.resetCrop(index)}
                                                                                    color='primary'
                                                                            >
                                                                                <RefreshIcon/>
                                                                                <span className="ml-3">Reset</span>
                                                                            </Button>
                                                                            <Button variant="contained"
                                                                                    component="span"
                                                                                    onClick={this.completeCrop(index)}
                                                                                    color='primary'
                                                                            >
                                                                                <CheckIcon/>
                                                                                <span className="ml-3">Done</span>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    item.croppedImgUrl && item.cropComplete &&
                                                                    <div>
                                                                        {$croppedImgPreview}
                                                                        <div className='mt-3'>
                                                                            <Button variant="contained"
                                                                                    component="span"
                                                                                    onClick={this.crop(index)}
                                                                                    color='primary'
                                                                            >
                                                                                <CropIcon/>
                                                                                <span className="ml-3">Crop</span>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <button
                                                    onClick={this.handleAddRow}
                                                    className="btn btn-outline-primary mt-4 ml-3"
                                                    type="button"
                                                >
                                                    Add Question
                                                </button>
                                                {
                                                    questions.length > 1 &&
                                                    <button
                                                        onClick={this.handleRemoveRow}
                                                        className="btn btn-outline-danger ml-3 mt-4"
                                                        type="button"
                                                    >
                                                        Remove Last Question
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        )
            ;
    }
}

AddHomeworkDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    addHomework: PropTypes.func.isRequired,
    getClassLevels: PropTypes.func.isRequired,
    getTeachingSubjects: PropTypes.func.isRequired,
    getStreams: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.addHomeworkData.data,
    status: state.addHomeworkData.status,
    loading: state.addHomeworkData.loading,
    levelsData: state.levelsData.data,
    subjectsData: state.getTeachingSubjectsData.data,
    streamsData: state.getStreamsData.data,
});

export default connect(mapStateToProps, {
    addHomework,
    getClassLevels,
    getStreams,
    getTeachingSubjects
})(withStyles(styles)(AddHomeworkDialog));