import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../themes/Themes";
import withStyles from "@material-ui/core/styles/withStyles";

const style = theme => ({});

class Loader extends Component {
    render() {
        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className='loader'>
                    <CircularProgress color='primary' size={50} thickness={5}/>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style, {withTheme: true})(Loader);