import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatForumPostDate} from "../helpers";

class TimeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: this.props.formatFunction(this.props.time)
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time:  this.props.formatFunction(this.props.time)}), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <span>{this.state.time}</span>
        );
    }
}

TimeComponent.propTypes = {
    time: PropTypes.string.isRequired,
    formatFunction: PropTypes.func.isRequired
};

export default TimeComponent;