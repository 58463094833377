import {loading} from "./helperActions";
import {
    default as actionTypes,
    GET_CHATS,
    SEND_MESSAGE,
    SEND_READ_MESSAGE,
    SEND_RECEIVED_MESSAGE
} from "../constants/actionTypes";
import axios from "../axios/axios";
import {CHAT_URL, default as urls} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig, PARENT_TO_TEACHER, TEACHER_TO_PARENT} from "../constants/constants";

export const getChats = () => dispatch => {
    dispatch(
        loading(GET_CHATS, {chats: []}, 0, true)
    );
    axios.get(`${CHAT_URL}/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_CHATS, response.data, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(GET_CHATS, error)
                );
            }
        )
};

export const newMessage = (currentChats, message) => dispatch => {
    const clonedCurrentChats = [...currentChats];
    const filtered = clonedCurrentChats.filter(chat => chat.id === message.id);
    console.log('filtered', filtered);
    if (filtered.length > 0) {
        clonedCurrentChats.forEach((item, index) => {
            if (item.id === message.id) {
                clonedCurrentChats.splice(index, 1, message);
            }
        });
    } else {
        clonedCurrentChats.push(message)
    }
    const data = {chats: clonedCurrentChats};
    dispatch(
        handleSuccessResponse(GET_CHATS, data, 200)
    );
};

/*export const addMessage = (currentChats, message) => dispatch => new Promise((resolve, reject) => {
  const chats = [...currentChats, message];
    const data = {chats: chats};
    dispatch(
        handleSuccessResponse(GET_CHATS, data, 200)
    );
    resolve();
});*/

export const addMessage = (currentChats, message) => dispatch => {
    const chats = [...currentChats, message];
    const data = {chats: chats};
    dispatch(
        handleSuccessResponse(GET_CHATS, data, 200)
    );
};

export const sendMessages = (currentChats, data) => dispatch => {
    dispatch(
        loading(SEND_MESSAGE, {}, 0, true)
    );
    axios.post(CHAT_URL, data, axiosConfig)
        .then(response => {
            const clonedCurrentChats = [...currentChats];
            const newChats = response.data.chats;
            clonedCurrentChats.forEach((item, index) => {
                if (item.category === TEACHER_TO_PARENT && item.sent === false) {
                    const filtered = newChats.filter(chat => chat.pendingId === item.pendingId);
                    if (filtered.length > 0) {
                        clonedCurrentChats.splice(index, 1, filtered[0]);
                    }
                }
            });
            dispatch(
                handleSuccessResponse(SEND_MESSAGE, response.data, response.status)
            );
            dispatch(handleSuccessResponse(GET_CHATS, {chats: clonedCurrentChats}, 200));
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_MESSAGE, error)
                );
            }
        )
};

export const sendReceivedMessages = (data) => dispatch => {
    dispatch(
        loading(SEND_RECEIVED_MESSAGE, {}, 0, true)
    );
    axios.post(`${CHAT_URL}/received`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(SEND_RECEIVED_MESSAGE, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_RECEIVED_MESSAGE, error)
                );
            }
        )
};

export const sendReadMessages = (data) => dispatch => {
    dispatch(
        loading(SEND_READ_MESSAGE, {}, 0, true)
    );
    axios.post(`${CHAT_URL}/read`, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(SEND_READ_MESSAGE, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_READ_MESSAGE, error)
                );
            }
        )
};