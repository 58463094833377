import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getTimetable} from "../../actions/timetableActions";
import withStyles from "@material-ui/core/es/styles/withStyles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import UserImgPlaceholder from '../../images/placeholders/user.jpg';
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import {fade} from "@material-ui/core/styles/colorManipulator";
import SmsIcon from '../../images/ic_sms_blue.png';
import SmsIconWhite from '../../images/ic_sms_white.png';
import SingleChat from "./SingleChat";
import LatestChats from "./LatestChats";
import io from 'socket.io-client';
import {PARENT_IMG_URL, REQUEST_BASE_URL, SOCKET_BASE_URL} from "../../constants/request_url";
import {
    addMessage,
    getChats,
    newMessage,
    sendMessages,
    sendReadMessages,
    sendReceivedMessages
} from "../../actions/chatActions";
import {getStudents} from "../../actions/studentActions";
import {PARENT_TO_TEACHER, TEACHER_TO_PARENT} from "../../constants/constants";
import {formatDateTime, formatStatusDate, getDateMillis, utcDate} from "../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import StudentContacts from "./StudentContacts";
import ArrowBackIcon from "../../images/arrow_back_white.png";
import SingleChatSm from "./SingleChatSm";

/*const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});*/


const styles = theme => ({
    search: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 1),
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    searchIconContainer: {
        position: 'absolute',
        pointerEvents: 'none',
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: 50,
            height: 50,
        }

    },
    searchIcon: {
        [theme.breakpoints.down('md')]: {
            width: 50,
            height: 50,
            padding: 5
        }

    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: 30,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: 35,
            paddingTop: 15,
            paddingRight: 15,
            paddingBottom: 15,
            paddingLeft: 50,
        }
    }
});

const statusSocket = io(`${SOCKET_BASE_URL}/get_parent_status`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
            }
        }
    },
    forceNew: true,
    reconnection: true
});

const chatSocket = io(`${SOCKET_BASE_URL}/message`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
            }
        }
    },
    forceNew: true,
    reconnection: true
});


class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: -1,
            userStatus: null,
            idNo: null,
            message: '',
            name: null,
            imageUri: null,
            contactsOpen: false,
            slideOut: false,
            parentIdNo: null,
            slideIn: true,
            chatOpen: false
        };
        this.scrollViewRef = React.createRef();
        this.setCurrentIndex = this.setCurrentIndex.bind(this);
        this.getUserStatus = this.getUserStatus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.getUnsentMessages = this.getUnsentMessages.bind(this);
        this.getUnReadMessages = this.getUnReadMessages.bind(this);
        this.getUnReceivedMessages = this.getUnReceivedMessages.bind(this);
        this.toggleContacts = this.toggleContacts.bind(this);
        this.switchParent = this.switchParent.bind(this);
        this.hideChats = this.hideChats.bind(this);
    }

    componentDidMount() {
        document.title = 'Chats';
        this.props.getStudents();
        this.props.getChats();

        chatSocket.connect();
        const thisRef = this;

        chatSocket.on(`teacher:${localStorage.getItem('teacherIdNo')}`, data => {
            thisRef.props.newMessage(this.props.data.chats, data);
            console.log(data);
            // thisRef.setState({userStatus: data});
        });

        this.getUnsentMessages();
        this.getUnReadMessages();
        this.getUnReceivedMessages();
    }

    getUnsentMessages = () => {
        setInterval(() => {
            const {data, sendingMessages} = this.props;
            const {chats} = data;
            const filtered = chats.filter(item => item.category === TEACHER_TO_PARENT && item.sent === false);
            if (filtered.length > 0 && !sendingMessages) {
                const submitData = JSON.stringify({
                    chats: filtered.map(item => ({
                        pendingId: item.pendingId,
                        message: item.message,
                        date: item.dateSent,
                        idNo: item.to.idNo
                    })),
                    fromWeb: true
                });
                this.props.sendMessages(chats, submitData);
            }
        }, 1000);
    };

    getUnReceivedMessages = () => {
        setInterval(() => {
            const {data, sendingReceivedMessages} = this.props;
            const {chats} = data;
            const filtered = chats.filter(item => item.category === PARENT_TO_TEACHER && !item.dateReceived &&
                item.read === false && item.sent === true);
            if (filtered.length > 0 && !sendingReceivedMessages) {
                console.log("unreceived", filtered);
                const submitData = JSON.stringify({
                    chats: filtered.map(item => ({
                        id: item.id,
                        date: formatDateTime(utcDate())
                    })),
                    fromWeb: true
                });
                this.props.sendReceivedMessages(submitData);
            }
        }, 1000);
    };

    getUnReadMessages = () => {
        setInterval(() => {
            const {data, sendingReadMessages} = this.props;
            const {idNo} = this.state;
            const {chats} = data;
            const filtered = chats.filter(item => item.category === PARENT_TO_TEACHER && item.dateReceived &&
                item.read === false && item.sent === true && item.from.idNo === idNo);
            if (filtered.length > 0 && !sendingReadMessages) {
                console.log("unread", filtered);
                const submitData = JSON.stringify({
                    chats: filtered.map(item => ({
                        id: item.id,
                        date: formatDateTime(utcDate())
                    })),
                    fromWeb: true
                });
                this.props.sendReadMessages(submitData);
            }
        }, 1000);
    };

    setCurrentIndex = (index, idNo, name, imageUri) => event => {
        if (idNo !== this.state.idNo) {
            this.setState({currentIndex: index, idNo: idNo, userStatus: null, name: name, imageUri: imageUri,
                    message: '', parentIdNo: null, chatOpen: true},
                () => {
                    this.getUserStatus(idNo);
                    // this.scrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
                    setTimeout(() => {
                        // console.log('scrollHeight', this.scrollViewRef.current.scrollHeight);
                        console.log('scrollEle', this.scrollViewRef.current.scrollHeight);
                        this.scrollViewRef.current.scrollTop = this.scrollViewRef.current.scrollHeight;
                    }, 1000);
                });
        }
    };

    switchParent = (idNo, name, imageUri) => event => {
        if (idNo !== this.state.idNo) {
            this.setState({parentIdNo: idNo, userStatus: null, name: name, imageUri: imageUri,
                    message: '', idNo: idNo},
                () => {
                    this.getUserStatus(idNo);
                    setTimeout(() => {
                        this.scrollViewRef.current.scrollTop = this.scrollViewRef.current.scrollHeight;
                    }, 1000);
                });
        }
        this.setState({slideOut: true, chatOpen: true},
            () => {
                setTimeout(() => {
                    this.setState({contactsOpen: false, slideOut: false})
                }, 1000);
            });
    };

    getUserStatus = idNo => {
        statusSocket.disconnect();
        statusSocket.connect();
        statusSocket.emit('get_current_status', {idNo: idNo});
        const thisRef = this;

        statusSocket.on(`parent:${idNo}`, data => {
            console.log(data);
            thisRef.setState({userStatus: data});
        });

        statusSocket.on('disconnect', data => {
            thisRef.setState({userStatus: null});
        });
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    submit = () => {
        const {message, idNo, name, imageUri} = this.state;
        const date = utcDate();
        const from = {
            "idNo": localStorage.getItem('teacherIdNo'),
            "imageUri": localStorage.getItem('teacherImageUri'),
            "name": ''
        };
        const to = {
            "idNo": idNo,
            "imageUri": imageUri,
            "name": name
        };
        const data = {
            pendingId: date.getTime(),
            category: TEACHER_TO_PARENT,
            message: message.trim(),
            dateSent: formatDateTime(date),
            to: to,
            from: from,
            sent: false,
            read: false
        };
        this.props.addMessage(this.props.data.chats, data);
        this.setState({message: ''});
        setTimeout(() => {
            this.scrollViewRef.current.scrollTop = this.scrollViewRef.current.scrollHeight;
        }, 100);
    };

    handleSubmit = event => {
        const {message} = this.state;
        if (message.trim().length > 0) {
            this.submit();
        }
    };

    onKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        const {message} = this.state;
        if (code === 13) {
            event.preventDefault();
            if (message.trim().length > 0) {
                this.submit();
            }
        }
    };

    toggleContacts = event => {
        const {contactsOpen} = this.state;
        if (contactsOpen) {
            this.setState({slideOut: true},
                () => {
                    setTimeout(() => {
                        this.setState({contactsOpen: false, slideOut: false})
                    }, 1000);
                });
        } else {
            this.setState({contactsOpen: true});
        }

    };

    hideChats = event => {
        this.setState({slideIn: false, currentIndex: -1, parentIdNo: null},
            () => {
                setTimeout(() => {
                    this.setState({chatOpen: false, slideIn: true})
                }, 1000);
            });
    };

    render() {
        const {data, loading, classes, studentsData, studentsLoading} = this.props;
        const {currentIndex, userStatus, message, contactsOpen, slideOut, idNo, name, imageUri, chatOpen, slideIn} = this.state;
        const {chats} = data;
        const {students} = studentsData;
        let latestChats = [];
        let parents = [];
        // let groupedChats = [];
        let currentChats = [];
        const map = new Map();

        if (students) {
            students.forEach(item => {
                item.parents.forEach(parent => {
                    if (!map.has(parent.idNo)) {
                        parents.push(parent);
                        map.set(parent.idNo, true)
                    }
                });
            });

            if (parents.length > 0 && chats.length > 0) {
                parents.forEach(parent => {
                    const parentChats = chats.filter(chat => chat.from.idNo === parent.idNo || chat.to.idNo === parent.idNo);
                    if (parentChats.length > 0) {
                        // groupedChats.push(parentChats);
                        const unread = parentChats.filter (parentChat => parentChat.read !== true && parentChat.category === PARENT_TO_TEACHER);
                        const sortedParentChats = parentChats.sort((item1, item2) => {
                            if (item1.dateSent < item2.dateSent) {
                                return 1
                            }

                            if (item1.dateSent > item2.dateSent) {
                                return -1
                            }

                            return 0
                        });
                        latestChats.push({...sortedParentChats[0], unread: unread.length});
                    }
                });
            }
        }

        latestChats = latestChats.sort((item1, item2) => {
            if (item1.dateSent < item2.dateSent) {
                return 1
            }

            if (item1.dateSent > item2.dateSent) {
                return -1
            }

            return 0
        });

        /*groupedChats = groupedChats.sort((item1, item2) => {
            if (item1[0].dateSent < item2[0].dateSent) {
                return -1
            }

            if (item1[0].dateSent > item2[0].dateSent) {
                return 1
            }

            return 0
        });

        groupedChats = groupedChats.reverse();*/

        let index = -1;

        let username = "";
        let imgUri = null;
        let status = null;
        if (idNo) {
            const parentChats = latestChats.filter(item => item.from.idNo === idNo || item.to.idNo === idNo);
            let chatIndex = -1;
            if (parentChats.length > 0) {
                latestChats.forEach((item, itemIndex) => {
                    if (item.from.idNo === idNo || item.to.idNo === idNo) {
                        chatIndex = itemIndex;
                    }
                })
            }

            if (chatIndex < 0) {
                currentChats.push(null)
            } else {
                currentChats = chats.filter(item => item.from.idNo === idNo || item.to.idNo === idNo).sort((item1, item2) => {
                    if (item1.dateSent < item2.dateSent) {
                        return -1
                    }

                    if (item1.dateSent > item2.dateSent) {
                        return 1
                    }

                    return 0
                });
                index = chatIndex;
            }

            if (userStatus) {
                if (userStatus.typing === true && userStatus.schoolId.toString() === localStorage.getItem("teacherSchoolId") &&
                    userStatus.typingTo === localStorage.getItem("teacherIdNo")) {
                    status = 'typing...';
                } else if  (userStatus.online === true) {
                    status = "Online"
                } else if (userStatus.lastSeen.length > 0) {
                    status = `last seen ${formatStatusDate(userStatus.lastSeen)}`
                }
            }
        }
        /*if (index >= 0) {
            if (!parentIdNo) {
                currentChats = groupedChats.
                currentChats = groupedChats[index];
                currentChats = currentChats.sort((item1, item2) => {
                    if (item1.dateSent < item2.dateSent) {
                        return -1
                    }

                    if (item1.dateSent > item2.dateSent) {
                        return 1
                    }

                    return 0
                });
            }
            console.log(currentChats);

            /!*if (latestChats[index].category === PARENT_TO_TEACHER) {
                username = latestChats[index].from.name;
                imgUri = latestChats[index].from.imageUri;
            } else {
                username = latestChats[index].to.name;
                imgUri = latestChats[index].to.imageUri;
            }*!/

            if (userStatus) {
                if (userStatus.typing === true && userStatus.schoolId.toString() === localStorage.getItem("teacherSchoolId") &&
                    userStatus.typingTo === localStorage.getItem("teacherIdNo")) {
                    status = 'typing...';
                } else if  (userStatus.online === true) {
                    status = "Online"
                } else if (userStatus.lastSeen.length > 0) {
                    status = `last seen ${formatStatusDate(userStatus.lastSeen)}`
                }
            }
        }*/

        console.log("current", latestChats);

        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="Chats"/>
                <div className="menu-drawer-h">
                    {/*{
                        loading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }*/}
                    <div className='chat-main-container'>
                        {
                            (loading || studentsLoading)  &&
                            <Paper elevation={2} className='chat-paper'>
                                <div className='w-100 d-flex justify-content-center align-items-center h-100 chats-loader'>
                                    <CircularProgress color='primary' size={50} thickness={4}/>
                                </div>
                            </Paper>
                        }
                        {
                            !loading && !studentsLoading &&
                            <Paper elevation={2} className='chat-paper'>
                                <div className='chat-top-bar'>
                                    <div className="search-container">
                                        <div className="search-container-wrapper">
                                            <div className={classes.search}>
                                                <div className={classes.searchIconContainer}>
                                                    <SearchIcon/>
                                                </div>
                                                <InputBase
                                                    placeholder="Search…"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput,
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>

                                        <div className='new-message-icon-wrapper'
                                             onClick={this.toggleContacts}>
                                            <img className='new-message-icon' src={SmsIconWhite}/>
                                        </div>
                                    </div>
                                    <div className='chat-top-bar-divider'/>
                                    {
                                        currentChats.length > 0 &&
                                        <div className='single-chat-top-bar'>
                                            {
                                                !imageUri &&
                                                <img src={UserImgPlaceholder} alt="profile-image" className='single-chat-img'/>
                                            }
                                            {
                                                imageUri &&
                                                <img src={`${PARENT_IMG_URL}/${imageUri}`} alt="profile-image" className='single-chat-img'/>
                                            }
                                            <div className='d-flex flex-column'>
                                                <div className='username-text text-capitalize text-white'>{name}</div>
                                                <div className='text-white last-seen-text'>{status}</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="chat-container">
                                    <LatestChats chats={latestChats} setCurrentIndex={this.setCurrentIndex}
                                                 currentIndex={index}/>

                                    <Divider style={{height: '100%', width: 1}}/>

                                    {
                                        currentChats.length > 0 &&
                                        <SingleChat chats={currentChats} scrollViewRef={this.scrollViewRef}
                                                    message={message} handleChange={this.handleChange}
                                                    handleSubmit={this.handleSubmit}
                                                    onKeyPress={this.onKeyPress}/>
                                    }
                                </div>
                                {
                                    contactsOpen &&
                                    <StudentContacts chats={latestChats} switchParent={this.switchParent}
                                                     currentIndex={index}
                                                     toggleContacts={this.toggleContacts}
                                                     slideOut={slideOut}
                                                     students={students}
                                    />
                                }
                            </Paper>
                        }
                    </div>

                    <div className='chat-main-container-sm'>
                        {
                            (loading || studentsLoading)  &&
                            <Paper elevation={2} className='chat-paper'>
                                <div className='w-100 d-flex justify-content-center align-items-center h-100 chats-loader'>
                                    <CircularProgress color='primary' size={50} thickness={4}/>
                                </div>
                            </Paper>
                        }

                        {
                            !loading && !studentsLoading &&
                            <Paper elevation={2} className='chat-paper'>
                                <div className='chat-top-bar'>
                                    {
                                        chatOpen === false &&
                                        <div className="search-container">
                                            <div className="search-container-wrapper">
                                                <div className={classes.search}>
                                                    <div className={classes.searchIconContainer}>
                                                        <SearchIcon className={classes.searchIcon}/>
                                                    </div>
                                                    <InputBase
                                                        placeholder="Search…"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput,
                                                        }}
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>

                                            <div className='new-message-icon-wrapper'
                                                 onClick={this.toggleContacts}>
                                                <img className='new-message-icon' src={SmsIconWhite}/>
                                            </div>
                                        </div>
                                    }
                                    {
                                        currentChats.length > 0 &&
                                        <div className='single-chat-top-bar'>
                                            <img src={ArrowBackIcon} className='arrow-back-status' onClick={this.hideChats}/>
                                            {
                                                !imageUri &&
                                                <img src={UserImgPlaceholder} alt="profile-image" className='single-chat-img'/>
                                            }
                                            {
                                                imageUri &&
                                                <img src={`${PARENT_IMG_URL}/${imageUri}`} alt="profile-image" className='single-chat-img'/>
                                            }
                                            <div className='d-flex flex-column'>
                                                <div className='username-text text-capitalize text-white'>{name}</div>
                                                <div className='text-white last-seen-text'>{status}</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="chat-container">
                                    {
                                        currentChats.length === 0 &&
                                        <LatestChats chats={latestChats} setCurrentIndex={this.setCurrentIndex}
                                                     currentIndex={index}/>
                                    }

                                    {
                                        chatOpen &&
                                        <SingleChatSm chats={currentChats} scrollViewRef={this.scrollViewRef}
                                                      message={message} handleChange={this.handleChange}
                                                      handleSubmit={this.handleSubmit}
                                                      onKeyPress={this.onKeyPress}
                                                      slideIn={slideIn}/>
                                    }
                                </div>
                                {
                                    contactsOpen &&
                                    <StudentContacts chats={latestChats} switchParent={this.switchParent}
                                                     currentIndex={index}
                                                     toggleContacts={this.toggleContacts}
                                                     slideOut={slideOut}
                                                     students={students}
                                    />
                                }
                            </Paper>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Chat.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getChats: PropTypes.func.isRequired,
    getStudents: PropTypes.func.isRequired,
    addMessage: PropTypes.func.isRequired,
    sendMessages: PropTypes.func.isRequired,
    sendReceivedMessages: PropTypes.func.isRequired,
    sendReadMessages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.chatsData.data,
    loading: state.chatsData.loading,
    status: state.chatsData.status,
    studentsData: state.studentsData.data,
    studentsLoading: state.studentsData.loading,
    sendingMessages: state.sendMessageData.loading,
    sendingReceivedMessages: state.sendReceivedMessageData.loading,
    sendingReadMessages: state.sendReceivedMessageData.loading
});

export default connect(mapStateToProps, {getChats, getStudents, newMessage, addMessage, sendMessages,
    sendReceivedMessages, sendReadMessages})(withStyles(styles, {withTheme: true})(Chat));