import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {RenderDateTimePicker, RenderTextField} from "./inputs";
import CloseIcon from "@material-ui/icons/Close";
import {Field, reduxForm} from "redux-form";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {isWeekend} from "date-fns";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import {WhiteCircularProgress} from "../progress_bars/ProgressBars";

const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

const validate = values => {
    const errors = {};
    const requiredFields = ['admNo', 'date', 'title', 'description'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    if (values.admNo && isNaN(values.admNo)) {
        errors.admNo = 'ADM NO must be a number'
    }

    return errors
};

class IssueLeaveForm extends Component {
    constructor(props) {
        super(props);
        this.shouldDisableDate = this.shouldDisableDate.bind(this);
    }

    shouldDisableDate = date => {
        return isWeekend(date);
    };

    render() {
        const {
            classes, pristine, submitting, handleSubmit, handleClose, err, success, errMes,
            successMessage, loading
        } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClose}
                                    aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Issue Leave
                        </Typography>
                        <Button color="inherit" type="submit"
                                disabled={pristine || submitting}>
                            {
                                !loading &&
                                <span>Issue</span>
                            }
                            {
                                loading &&
                                <WhiteCircularProgress size={30} thickness={5}/>
                            }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className='container menu-drawer-h d-flex justify-content-center'>
                    <div className='w-50 pt-5'>
                        {err && <div className="alert alert-danger">{errMes}</div>}
                        {success && <div className="alert alert-success">{successMessage}</div>}
                        <div>
                            <Field name="admNo"
                                   component={RenderTextField}
                                   label="Adm No."
                                   style={{marginTop: 20}}
                                   placeholder="Adm No"
                                   margin="normal"
                                   fullWidth
                                   InputLabelProps={{
                                       shrink: true,
                                       className: classes.label
                                   }}
                                   onChange={this.handleChange}
                                   InputProps={{
                                       classes: {
                                           input: classes.resize
                                       }
                                   }}/>
                        </div>

                        <div>
                            <Field name="title"
                                   component={RenderTextField}
                                   label="Title"
                                   style={{marginTop: 20}}
                                   placeholder="title"
                                   fullWidth
                                   margin="normal"
                                   InputLabelProps={{
                                       shrink: true,
                                       className: classes.label
                                   }}
                                   onChange={this.handleChange}
                                   InputProps={{
                                       classes: {
                                           input: classes.resize
                                       }
                                   }}/>
                        </div>

                        <div>
                            <Field name="description"
                                   component={RenderTextField}
                                   label="Description"
                                   style={{marginTop: 20}}
                                   placeholder="description"
                                   fullWidth
                                   margin="normal"
                                   InputLabelProps={{
                                       shrink: true,
                                       className: classes.label
                                   }}
                                   onChange={this.handleChange}
                                   InputProps={{
                                       classes: {
                                           input: classes.resize
                                       }
                                   }}/>
                        </div>
                        <div>
                            <Field name="date"
                                   component={RenderDateTimePicker}
                                   leftArrowIcon={<ChevronLeftIcon/>}
                                   rightArrowIcon={<ChevronRightIcon/>}
                                   label="Due Date"
                                   fullWidth
                                   InputLabelProps={{
                                       shrink: true,
                                       className: classes.label
                                   }}
                                   InputProps={{
                                       classes: {
                                           input: classes.resize
                                       }
                                   }}
                                   className="mb-4"
                                   disablePast
                                   shouldDisableDate={this.shouldDisableDate}
                                   style={{marginTop: 20}}/>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

IssueLeaveForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

IssueLeaveForm = reduxForm({
    form: 'IssueLeaveForm',
    validate,
})(IssueLeaveForm);

IssueLeaveForm = connect(
    state => ({
        initialValues: {
            date: new Date()
        }
    })
)(IssueLeaveForm);

export default withStyles(styles, {withTheme: true})(IssueLeaveForm);