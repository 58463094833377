import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getOverallResults} from "../../actions/resultActions";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {titleCase} from "../../helpers";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ReactTable from "react-table";
import {maxYear, minYear} from "../../constants/constants";
import ResultDialog from "../dialogs/ResultDialog";
import Paper from "@material-ui/core/Paper/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {JssProvider} from 'react-jss';
import {create} from "jss";
import {createGenerateClassName, jssPreset} from '@material-ui/core/styles';
import {getClassLevels} from "../../actions/classLevelActions";
import {getExams} from "../../actions/examActions";
import {getTerms} from "../../actions/termsActions";

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

const styles = ({});

const getColumnWidth = (rows, accessor, headerText) => {
    if (rows) {
        const maxWidth = 400;
        const magicSpacing = 10;
        const cellLength = Math.max(
            ...rows.map(row => (`${row[accessor]}` || '').length),
            headerText.length,
        );
        return Math.min(maxWidth, cellLength * magicSpacing)
    }
};

class OverallResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classLevel: '',
            year: new Date().getFullYear(),
            term: '',
            exam: '',
            currentRow: null,
            openDialog: false
        };
        this.renderCell = this.renderCell.bind(this);
        this.renderStream = this.renderStream.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClose = this.handleClose.bind(this)
        this.renderGrade = this.renderGrade.bind(this);

    }

    componentDidMount() {
        document.title = 'Overall Results';
        this.props.getClassLevels();
        this.props.getTerms();
    }

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                const {classLevel, year, term, exam} = this.state;
                if (classLevel !== "" && year !== "" && term !== '' && exam !== '') {
                    this.props.getOverallResults(classLevel, year, term, exam);
                }

                if (event.target.name === 'classLevel' || event.target.name === 'year'
                    || event.target.name === 'term') {
                    const {classLevel, year, term} = this.state;
                    if (classLevel !== '' && year !== '' && term !== '') {
                        this.props.getExams(classLevel, term, year);
                    }
                }

            }
        );

    };

    renderCell = cellInfo => {
        const {results} = this.props.data;

        return (
            <div
                style={{backgroundColor: "#fafafa"}}
                className="d-flex justify-content-center"
            >
                <div>{results[cellInfo.index][cellInfo.column.id]}</div>
            </div>
        );
    };

    renderGrade = cellInfo => {
        const {results} = this.props.data;

        return (
            <div
                style={{backgroundColor: "#fafafa"}}
                className="d-flex justify-content-center"
            >
                <div>{results[cellInfo.index][cellInfo.column.id].toUpperCase()}</div>
            </div>
        );
    };

    renderStream = cellInfo => {
        const {results} = this.props.data;
        return (
            <div
                style={{backgroundColor: "#fafafa"}}
                className="d-flex justify-content-center"
            >
                <div>{results[cellInfo.index][cellInfo.column.id]['streamName']}</div>
            </div>
        );
    };

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    };

    render() {
        const {classLevel, exam, term, year, currentRow, openDialog} = this.state;
        const {data, status, loading, examsData, termsData, levelsData, classes} = this.props;
        const {results, subjects} = data;
        const columns = [
            {
                Header: 'ADM NO',
                accessor: 'admNo',
                Cell: this.renderCell
            },
            {
                Header: () => {
                    return (
                        <div className="text-left">NAME</div>
                    )
                },
                accessor: 'fullName',
                className: 'overflow-unset',
                Cell: cellInfo => (
                    <div>{titleCase(results[cellInfo.index]['fullName'])}</div>
                ),
                width: getColumnWidth(results, 'fullName', 'NAME')
            },
            {
                Header: 'STREAM',
                accessor: 'stream.streamName',
                id: 'stream',
                Cell: this.renderStream
            }
        ];

        if (subjects) {
            subjects.forEach(function (subject) {
                columns.push({
                    Header: subject.shortName.toUpperCase(),
                    accessor: `${subject.id.toString()}.score`,
                    Cell: cellInfo => {
                        if (results[cellInfo.index][subject.id] && results[cellInfo.index][subject.id.toString()]['score']) {
                            return (
                                <div
                                    style={{backgroundColor: "#fafafa"}}
                                    className="d-flex justify-content-center"
                                >
                                    <div>{results[cellInfo.index][subject.id.toString()]['score']}</div>
                                </div>
                            )
                        }

                        return (
                            <div
                                style={{backgroundColor: "#fafafa"}}
                                className="d-flex justify-content-center"
                            >
                                <div>_</div>
                            </div>
                        )
                    }
                })
            })
        }

        columns.push({
            Header: "TOTAL",
            accessor: 'total',
            Cell: this.renderCell
        });

        if (data.hasPoints) {
            columns.push({
                Header: "PTS",
                accessor: 'points',
                Cell: this.renderCell
            });
        }

        if (data.hasGrades) {
            columns.push({
                Header: "GRADE",
                accessor: 'meanGrade',
                Cell: this.renderGrade
            });
        }

        columns.push({
            Header: 'S.POS',
            accessor: 'streamPosition',
            Cell: this.renderCell,
        });

        columns.push({
            Header: "O.POS",
            accessor: 'position',
            Cell: this.renderCell
        });

        const years = [];
        for (let i = minYear; i <= maxYear; i++) {
            years.push(i);
        }

        return (
            <JssProvider jss={jss} generateClassName={generateClassName}>
                <MuiThemeProvider theme={mainTheme}>
                    <MenuDrawer title="overall results"/>
                    <div className="menu-drawer-h p-5">
                        <div className="mb-3 d-flex justify-content-between">
                            <div className='row w-100'>
                                <div className="col-lg-3 mb-sm-3">
                                    {
                                        !levelsData.levels &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="level-label-placeholder">
                                                {titleCase(localStorage.getItem("teacherLevelName"))}
                                            </InputLabel>
                                            <Select
                                                value={classLevel}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="classLevel"
                                                              id="level-label-placeholder"/>}
                                                displayEmpty
                                                name="classLevel"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem value=''>
                                                    <CircularProgress color='primary' size={30}
                                                                      thickness={5}/>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        levelsData.levels &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="level-label-placeholder">
                                                {titleCase(localStorage.getItem("teacherLevelName"))}
                                            </InputLabel>
                                            <Select
                                                value={classLevel}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="classLevel"
                                                              id="level-label-placeholder"/>}
                                                displayEmpty
                                                name="classLevel"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem
                                                    value=''>{titleCase(localStorage.getItem("teacherLevelName"))}...</MenuItem>
                                                {
                                                    levelsData.levels.map((item, index) => (
                                                        <MenuItem value={item.id} key={index}>
                                                            {titleCase(item.name)}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div className="col-lg-3 mb-sm-3">
                                    <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel shrink htmlFor="year-label-placeholder">
                                            Year
                                        </InputLabel>
                                        <Select
                                            value={year}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="year" id="year-label-placeholder"/>}
                                            displayEmpty
                                            name="year"
                                            className={classes.selectEmpty}
                                        >
                                            {
                                                years.map((item, index) => (
                                                    <MenuItem value={item} key={index}>{item}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-lg-3 mb-sm-3">
                                    {
                                        !termsData.terms &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="term-label-placeholder">
                                                {titleCase(localStorage.getItem("teacherTermName"))}
                                            </InputLabel>
                                            <Select
                                                value={term}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="term"
                                                              id="term-label-placeholder"/>}
                                                displayEmpty
                                                name="term"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem value=''>
                                                    <CircularProgress color='primary' size={30}
                                                                      thickness={5}/>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        termsData.terms &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="term-label-placeholder">
                                                {titleCase(localStorage.getItem("teacherTermName"))}
                                            </InputLabel>
                                            <Select
                                                value={this.state.term}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="term"
                                                              id="term-label-placeholder"/>}
                                                displayEmpty
                                                name="term"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem
                                                    value=''>{titleCase(localStorage.getItem("teacherTermName"))}...</MenuItem>
                                                {
                                                    termsData.terms.map((item, index) => (
                                                        <MenuItem value={item.id} key={index}>
                                                            {titleCase(item.name)}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div className="col-lg-3 mb-sm-3">
                                    {
                                        !examsData.exams &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="exam-label-placeholder">
                                                Exam
                                            </InputLabel>
                                            <Select
                                                value={exam}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="exam"
                                                              id="exam-label-placeholder"/>}
                                                displayEmpty
                                                name="exam"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem value=''>Exam...</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        examsData.exams &&
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel shrink htmlFor="exam-label-placeholder">
                                                Exam
                                            </InputLabel>
                                            <Select
                                                value={exam}
                                                onChange={this.handleSelectChange}
                                                input={<Input name="exam"
                                                              id="exam-label-placeholder"/>}
                                                displayEmpty
                                                name="exam"
                                                className={classes.selectEmpty}
                                            >
                                                <MenuItem value=''>Exam...</MenuItem>
                                                {
                                                    examsData.exams.map((item, index) => (
                                                        <MenuItem value={item.id} key={index}>
                                                            {titleCase(item.name)}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            loading &&
                            <div className='w-100 d-flex justify-content-center pt-5'>
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            </div>
                        }

                        {
                            results && subjects && !loading &&
                            <ReactTable
                                data={results}
                                columns={columns}
                                defaultPageSize={8}
                                className="-striped -highlight"
                                showPageSizeOptions={false}
                                minRows={0}
                                defaultSorted={[{
                                    id: 'position',
                                    desc: false,
                                }]}
                                filterable={true}
                                getTrProps={(state, rowInfo, column, instance) => ({
                                    onClick: e => {
                                        const row = results[rowInfo.index];
                                        const columnKeys = Object.keys(row);
                                        const _data = {};
                                        _data['admNo'] = row.admNo;
                                        _data['fullName'] = row.fullName;
                                        _data['stream'] = row.stream;
                                        _data['streamPosition'] = row.streamPosition;
                                        _data['position'] = row.position;
                                        _data['total'] = row.total;
                                        if (data.hasGrades) {
                                            _data['meanGrade'] = row.meanGrade;
                                        }
                                        if (data.hasPoints) {
                                            _data['totalPoints'] = row.points;
                                        }
                                        /*if (formLevel > 2 || (formLevel < 3 && category === 4)) {
                                            _data['meanGrade'] = row.meanGrade;
                                            _data['totalPoints'] = row.totalPoints;
                                        }*/
                                        _data['subjects'] = [];
                                        subjects.forEach(function (subject) {
                                            columnKeys.forEach(function (key) {
                                                if (subject.id.toString() === key) {
                                                    const subjectResult = row[key];
                                                    if (subjectResult && subjectResult.score) {
                                                        _data['subjects'].push({
                                                            "subjectName": subject.name,
                                                            "score": subjectResult.score,
                                                            "points": subjectResult.points,
                                                            "grade": subjectResult.grade,
                                                        });
                                                    }
                                                }
                                            });
                                        });
                                        this.setState({
                                            currentRow: _data,
                                            openDialog: true
                                        })
                                    }
                                })}
                            />
                        }

                        {
                            data.error && status > 300 &&
                            <Paper className="p-3">{data.message}</Paper>
                        }

                        <ResultDialog data={currentRow} open={openDialog} handleClose={this.handleClose}/>

                    </div>
                </MuiThemeProvider>
            </JssProvider>
        );
    }
}

OverallResults.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getOverallResults: PropTypes.func.isRequired,
    getClassLevels: PropTypes.func.isRequired,
    getExams: PropTypes.func.isRequired,
    getTerms: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.overallResultsData.data,
    status: state.overallResultsData.status,
    loading: state.overallResultsData.loading,
    levelsData: state.levelsData.data,
    termsData: state.termsData.data,
    examsData: state.examsData.data
});

export default connect(mapStateToProps,
    {
        getOverallResults,
        getClassLevels,
        getExams,
        getTerms
    }
)(withStyles(styles, {withTheme: true})(OverallResults));