import * as ActionTypes from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false
};

export default (state = initialState, action) => {
    // const newState = Object.assign({}, state);
    const newState = {...state};

    switch (action.type) {
        case ActionTypes.BLACKLIST_TOKEN:
            newState.data = action.data;
            newState.status = action.status;
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
