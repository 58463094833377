import {createMuiTheme} from "@material-ui/core";

const breakpointValues = {
  xs: 0,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
};

export const mainTheme = createMuiTheme({
    palette: {
        primary: {main: '#6699CC'},
        secondary: {main: '#84ADD6'},
        type: 'light'
    },
    status: {},
    typography: {
        htmlFontSize: 14,
        title: {
            color: '#FFF'
        },
        useNextVariants: true
    },
    breakpoints: {
        values: breakpointValues
    }
});