import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper/Paper";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Divider from "@material-ui/core/Divider/Divider";

class Tasks extends Component {
    render() {
        return (
            <div>
                <Paper className="p-0">
                    <div className="bg-primary-1 text-white font-weight-bold p-2">
                        Tasks
                    </div>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Add English Results</div>
                                <div className="col-3">20/4/2018</div>
                            </div>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Attend meeting</div>
                                <div className="col-3">14/3/2018</div>
                            </div>
                        </ListItem>
                    </List>

                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Mark attendance for today.</div>
                                <div className="col-3">16/2/2018</div>
                            </div>
                        </ListItem>
                    </List>

                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Add Form I CAT English results</div>
                                <div className="col-3">30/4/2018</div>
                            </div>
                        </ListItem>
                    </List>

                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Add Form I CAT Mathematics results</div>
                                <div className="col-3">30/4/2018</div>
                            </div>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Add Form II CAT English results</div>
                                <div className="col-3">30/4/2018</div>
                            </div>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem className="p-2">
                            <div className="row w-100">
                                <div className="col-9">Add Form IV End Term Mathematics results</div>
                                <div className="col-3">30/4/2018</div>
                            </div>
                        </ListItem>
                    </List>
                </Paper>
            </div>
        );
    }
}

Tasks.propTypes = {};

export default Tasks;