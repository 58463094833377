import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../../themes/Themes";
import Overview from "./Overview";
import EventCalendar from "./EventCalendar";
import Tasks from "./Tasks";

class Dashboard extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        document.title = 'Dashboard';
    }

    render() {
        return (
            <MuiThemeProvider theme={mainTheme}>
                <MenuDrawer title="DASHBOARD"/>
                <div className="container menu-drawer-h">
                    <div className="pt-5">
                        <div className="row">
                            <div className="col-lg-8">
                                <EventCalendar/>
                            </div>
                            <div className="col-lg-4">
                                <Tasks/>
                            </div>
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Dashboard.propTypes = {};

export default Dashboard;