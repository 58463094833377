import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {LastLocationProvider} from 'react-router-last-location';

import Login from './components/auth/Login';
import PageNotFound from './components/errors/PageNotFound';
import {connect} from 'react-redux';
import {validateToken} from "./actions/authActions";
import PrivateRoute from './components/auth/PrivateRoute';
// import favicon from './images/favicon.ico';
import {Helmet} from 'react-helmet';
import Dashboard from "./components/dashboard/Dashboard";
import favicon from './images/favicon.ico';
import LoginRoute from "./components/auth/LoginRoute";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import Results from "./components/pages/Results";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Attendance from "./components/pages/Attendance";
import Events from "./components/pages/Events";
import Event from './components/pages/Event';
import Subjects from "./components/pages/Subjects";
import OverallResults from "./components/pages/OverallResults";
import IssuedLeaves from "./components/pages/IssuedLeaves";
import Preloader from "./components/pages/Preloader";
import AuthErrorDialog from "./components/errors/AuthErrorDialog";
import {clearLocalStorage} from "./common";
import Homework from "./components/pages/Homework";
import HomeworkQuestions from "./components/pages/HomeworkQuestions";
import Notices from "./components/pages/Notices";
import Notice from "./components/pages/Notice";
import Assignments from "./components/pages/Assignments";
import Timetable from "./components/pages/Timetable";
import * as firebase from 'firebase';
// import 'firebase/messaging';
import {FIREBASE_CONFIG} from "./constants/constants";
import {sendDeviceToken} from "./actions/fcmActions";
import Chat from "./components/pages/Chat";
import io from "socket.io-client";
import {SOCKET_BASE_URL} from "./constants/request_url";
import Forum from "./components/pages/Forum";
import {ToastContainer} from "react-toastify";

// const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
// const fcmMessaging = firebaseApp.messaging();
// fcmMessaging.usePublicVapidKey('BCt7bP_U1NNEyMH5Kn3KY2KDBL2ccxakwVOzmk-BhH_1QUU04Vkwk5WXL4ROFMKbij6HWb2y_3-yRK5RHBjRDFA');

const statusSocket = io(`${SOCKET_BASE_URL}/status`, {
            path: '',
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('teacherToken')}`
                    }
                }
            }
});


class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.validateToken();
        statusSocket.connect();
        /*fcmMessaging.getToken().then((currentToken) => {
            if (currentToken) {
                // sendTokenToServer(currentToken);
                // updateUIForPushEnabled(currentToken);
                console.log(currentToken);
                localStorage.setItem("deviceId", currentToken)
            } else {
                // Show permission request.
                console.log('No Instance ID token available. Request permission to generate one.');
                // Show permission UI.
                // updateUIForPushPermissionRequired();
                // setTokenSentToServer(false);
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // showToken('Error retrieving Instance ID token. ', err);
            // setTokenSentToServer(false);
        });

        fcmMessaging.onTokenRefresh(() => {
            fcmMessaging.getToken().then((refreshedToken) => {
                console.log('Token refreshed.');
                localStorage.setItem("deviceId", refreshedToken);
                // Indicate that the new Instance ID token has not yet been sent to the
                // app server.
                // setTokenSentToServer(false);
                // Send Instance ID token to app server.
                // sendTokenToServer(refreshedToken);
                // ...
            }).catch((err) => {
                console.log('Unable to retrieve refreshed token ', err);
                // showToken('Unable to retrieve refreshed token ', err);
            });
        });

        fcmMessaging.onMessage((payload) => {
            console.log('Message received. ', payload);
        });*/

        document.addEventListener('visibilitychange', () => {
            if (document.hidden === true) {
                statusSocket.disconnect();
            } else {
                statusSocket.connect();
            }
        })

    }

    componentWillMount() {
        statusSocket.disconnect();
    }

    render() {
        const {error} = this.props.data;
        const {status, sessionExpData, loading} = this.props;
        let sessionExp = false;
        if (sessionExpData.error) {
            sessionExp = true;
            clearLocalStorage();
        }

        return (
            <Router>
                <LastLocationProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <link rel="icon" href={favicon}/>
                        </Helmet>
                        <ToastContainer/>
                        <div>
                            {
                                !status &&
                                <Preloader/>
                            }
                            {
                                !loading && (error === true || error === false) && !sessionExp &&
                                <Switch>
                                    <LoginRoute path={'/login'} component={Login} exact strict
                                                authError={error}/>
                                    {/*<Route path={'/login'} component={Login} exact strict/>*/}

                                    <PrivateRoute authError={error} path={'/'}
                                                  component={Events} exact strict/>
                                    <PrivateRoute
                                        authError={error} path={'/result'}
                                        component={Results} exact strict/>
                                    <PrivateRoute
                                        authError={error} path={'/attendance'}
                                        component={Attendance} exact strict/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/event/:id" component={Event}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/subject" component={Subjects}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/overall" component={OverallResults}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/leave" component={IssuedLeaves}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/homework" component={Assignments}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/notice" component={Notices}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/notice/:id" component={Notice}/>
                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/timetable" component={Timetable}/>

                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/chat" component={Chat}/>

                                    <PrivateRoute authError={error}
                                                  exact
                                                  strict
                                                  path="/forum" component={Forum}/>

                                    <PrivateRoute path="/*" exact strict component={PageNotFound}
                                                  authError={error}/>
                                </Switch>
                            }
                            <AuthErrorDialog open={sessionExp}/>
                        </div>
                    </MuiPickersUtilsProvider>
                </LastLocationProvider>
            </Router>
        );
    }
}

App.prototypes = {
    validateToken: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.validateTokenData.data,
    status: state.validateTokenData.status,
    loading: state.validateTokenData.loading,
    sessionExpData: state.sessionExpData.data
});

export default connect(mapStateToProps, {validateToken, sendDeviceToken})(App);
