import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import {composeWithDevTools} from "redux-devtools-extension";

const initialState = {};

const middleware = [thunk];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = composeWithDevTools({trace: true});

/*const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware));*/

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)));

export default store;
