import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Chip from "@material-ui/core/Chip";
import PrimaryTickIcon from "../../images/ic_tick_primary.png";
import TickIcon from "../../images/ic_tick.png";
import DoubleTickIcon from "../../images/ic_tick_double.png";
import {fade} from "@material-ui/core/styles/colorManipulator";
import withStyles from "@material-ui/core/styles/withStyles";
import {InputBase} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import Fab from "@material-ui/core/Fab";
import Textarea from "@material-ui/core/InputBase/Textarea";
import {PARENT_TO_TEACHER} from "../../constants/constants";
import {formatChatGroupDate, formatLatestChatDate, formatTime} from "../../helpers";
import ClockIcon from '../../images/square_clock.png';

const styles = theme => ({
    inputRoot: {
        color: 'inherit',
        background: '#FFFFFF',
        height: 'auto',
        width: 'calc(100% - 77px)',
        borderRadius: 5,
        padding: 20
    },
    inputInput: {
        width: '100%',
        fontSize: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 35
        }
    },
    chip: {
        marginTop: 10,
        marginBottom: 10
    },
    fab: {
        // margin: theme.spacing.unit,
        marginLeft: 10
    },
    chipLabel: {
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        }
    },
    chipRoot: {
        [theme.breakpoints.down('md')]: {
            padding: 10,
            height: 'auto'
        }
    },
    chipOutlined: {
        [theme.breakpoints.down('md')]: {
            borderRadius: 50
        }
    }
});

class SingleChatSm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomPadding: 0
        };
        this.bottomBarRef = React.createRef();
    }

    componentDidMount() {
        this.resizeObserver = new ResizeObserver((entries) => {
            this.setState({
                bottomPadding: entries[0].contentRect.height + 16
            });
        });

        this.resizeObserver.observe(this.bottomBarRef.current);
    }

    componentWillUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    render() {
        const {classes, scrollViewRef, message, slideIn} = this.props;
        const {bottomPadding} = this.state;
        const {chats} = this.props;

        return (
            <div className={`single-chat-main-container  animated ${slideIn ? 'slideInUp' : 'slideOutDown'} faster`} style={{paddingBottom: bottomPadding}}>
                    <div className='single-chat-inner-container' ref={scrollViewRef}>
                    {
                        chats.map((item, index) => {
                            if (item) {
                                if (item.category === PARENT_TO_TEACHER) {
                                    if (index === 0) {
                                        return (
                                            <div key={index}>
                                                <div className='d-flex justify-content-center'>
                                                    <Chip label={formatChatGroupDate(item.dateSent)}
                                                          className={
                                                              classes.chip
                                                          }
                                                          classes={{
                                                              label: classes.chipLabel,
                                                              root: classes.chipRoot,
                                                              outlined: classes.chipOutlined
                                                          }}
                                                          variant="outlined"
                                                          color='primary'/>
                                                </div>
                                                <div className="msg" key={index}>
                                                    <div className="bubble">
                                                        <div className="txt">
                                                            <div className="message">
                                                                {item.message}
                                                            </div>
                                                            <div
                                                                className='d-flex justify-content-end w-100 timestamp-container'>
                                                                <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                            </div>

                                                        </div>
                                                        <div className="bubble-arrow"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (formatChatGroupDate(item.dateSent) !== formatChatGroupDate(chats[index - 1].dateSent)) {
                                        return (
                                            <div key={index}>
                                                <div className='d-flex justify-content-center'>
                                                    <Chip label={formatChatGroupDate(item.dateSent)}
                                                          className={classes.chip}
                                                          variant="outlined"
                                                          color='primary'
                                                          classes={{
                                                              label: classes.chipLabel,
                                                              root: classes.chipRoot,
                                                              outlined: classes.chipOutlined
                                                          }}/>
                                                </div>
                                                <div className="msg" key={index}>
                                                    <div className="bubble">
                                                        <div className="txt">
                                                            <div className="message">
                                                                {item.message}
                                                            </div>
                                                            <div
                                                                className='d-flex justify-content-end w-100 timestamp-container'>
                                                                <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                            </div>

                                                        </div>
                                                        <div className="bubble-arrow"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (item.category === chats[index -1].category) {
                                        return (
                                            <div className="msg" key={index}>
                                                <div className="bubble follow">
                                                    <div className="txt">
                                                        <div
                                                            className="message follow">{item.message}</div>
                                                        <div className='d-flex justify-content-end w-100 timestamp-container'>
                                                            <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="msg" key={index}>
                                                <div className="bubble">
                                                    <div className="txt">
                                                        <div className="message">
                                                            {item.message}
                                                        </div>
                                                        <div
                                                            className='d-flex justify-content-end w-100 timestamp-container'>
                                                            <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                        </div>

                                                    </div>
                                                    <div className="bubble-arrow"/>
                                                </div>
                                            </div>
                                        )
                                    }
                                } else {
                                    if (index === 0) {
                                        return (
                                            <div key={index}>
                                                <div className='d-flex justify-content-center'>
                                                    <Chip label={formatChatGroupDate(item.dateSent)}
                                                          className={classes.chip}
                                                          variant="outlined"
                                                          color='primary'
                                                          classes={{
                                                              label: classes.chipLabel,
                                                              root: classes.chipRoot,
                                                              outlined: classes.chipOutlined
                                                          }}/>
                                                </div>
                                                <div className="msg">
                                                    <div className="bubble altfollow">
                                                        <div className="txt">
                                                            <p className="message follow">
                                                                {item.message}
                                                            </p>
                                                            <div
                                                                className='d-flex justify-content-end w-100 timestamp-container'>
                                                                <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                                {
                                                                    item.read &&
                                                                    <img src={PrimaryTickIcon} alt=""
                                                                         className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.read === false && item.dateReceived &&
                                                                    <img src={DoubleTickIcon} alt="" className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.read === false && !item.dateReceived && item.sent === true &&
                                                                    <img src={TickIcon} alt="" className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.sent === false &&
                                                                    <img src={ClockIcon} alt="" className='tick-icon'/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="bubble-arrow alt"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (formatChatGroupDate(item.dateSent) !== formatChatGroupDate(chats[index - 1].dateSent)) {
                                        return (
                                            <div key={index}>
                                                <div className='d-flex justify-content-center'>
                                                    <Chip label={formatChatGroupDate(item.dateSent)}
                                                          className={classes.chip}
                                                          variant="outlined"
                                                          color='primary'
                                                          classes={{
                                                              label: classes.chipLabel,
                                                              root: classes.chipRoot,
                                                              outlined: classes.chipOutlined
                                                          }}/>
                                                </div>
                                                <div className="msg">
                                                    <div className="bubble altfollow">
                                                        <div className="txt">
                                                            <p className="message follow">
                                                                {item.message}
                                                            </p>
                                                            <div
                                                                className='d-flex justify-content-end w-100 timestamp-container'>
                                                                <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                                {
                                                                    item.read &&
                                                                    <img src={PrimaryTickIcon} alt=""
                                                                         className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.read === false && item.dateReceived &&
                                                                    <img src={DoubleTickIcon} alt="" className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.read === false && !item.dateReceived && item.sent === true &&
                                                                    <img src={TickIcon} alt="" className='tick-icon'/>
                                                                }
                                                                {
                                                                    item.sent === false &&
                                                                    <img src={ClockIcon} alt="" className='tick-icon'/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="bubble-arrow alt"/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (item.category === chats[index - 1].category) {
                                        return (
                                            <div className="msg" key={index}>
                                                <div className="bubble altfollow">
                                                    <div className="txt">
                                                        <p className="message follow">
                                                            {item.message}
                                                        </p>
                                                        <div
                                                            className='d-flex justify-content-end w-100 timestamp-container'>
                                                            <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                            {
                                                                item.read &&
                                                                <img src={PrimaryTickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.read === false && item.dateReceived &&
                                                                <img src={DoubleTickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.read === false && !item.dateReceived && item.sent === true &&
                                                                <img src={TickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.sent === false &&
                                                                <img src={ClockIcon} alt="" className='tick-icon'/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="msg" key={index}>
                                                <div className="bubble altfollow">
                                                    <div className="txt">
                                                        <p className="message follow">
                                                            {item.message}
                                                        </p>
                                                        <div
                                                            className='d-flex justify-content-end w-100 timestamp-container'>
                                                            <div className="timestamp">{formatTime(item.dateSent)}</div>
                                                            {
                                                                item.read &&
                                                                <img src={PrimaryTickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.read === false && item.dateReceived &&
                                                                <img src={DoubleTickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.read === false && !item.dateReceived && item.sent === true &&
                                                                <img src={TickIcon} alt="" className='tick-icon'/>
                                                            }
                                                            {
                                                                item.sent === false &&
                                                                <img src={ClockIcon} alt="" className='tick-icon'/>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="bubble-arrow alt"/>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            }
                        })
                    }
                </div>
                <div className='chat-bottom-bar' ref={this.bottomBarRef}>
                    <InputBase fullWidth placeholder='Type a message'
                               multiline
                               rows={1}
                               rowsMax={5}
                               classes={{
                                   root: classes.inputRoot,
                                   input: classes.inputInput,
                               }}
                               value={message}
                               name='message'
                               onChange={this.props.handleChange}
                               onKeyPress={this.props.onKeyPress}/>
                    <div className='fab' style={{background: message.trim().length === 0 ? 'rgba(0, 0, 0, 0.12)' : '#6699CC'}}
                         onClick={this.props.handleSubmit}>
                        <SendIcon style={{width: 50, height: 50, color: message.trim().length === 0 ? 'rgba(0, 0, 0, 0.26)' : '#FFFFFF'}}/>
                    </div>
                </div>
            </div>
        );
    }
}

SingleChatSm.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    chats: PropTypes.array.isRequired,
    scrollViewRef: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    slideIn: PropTypes.bool.isRequired,
};

export default withStyles(styles, {withTheme: true})(SingleChatSm);