import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getTeachingSubjects = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_TEACHING_SUBJECTS, {}, 0, true)
    );
    axios.get(`${urls.SUBJECT_URL}teacher`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_TEACHING_SUBJECTS, response.data, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(actionTypes.GET_TEACHING_SUBJECTS, error)
                );
            }
        )
};
